<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      drawerName="Ajuste de valor do relatório"
      @close="handleClose"
      @save="handleDetail"
    >
      <div class="drawer-detail">
        <span> Informações do lançamento: </span>
        <div div class="drawer-detail__header">
          <el-row
            v-for="(info, index) in getShowInfo"
            :key="`drawerInfo-${info.label}-${index}`"
          >
            <span>{{ info.label }}</span>
            {{ info.value }}
          </el-row>
        </div>
        <div class="drawer-detail__edit">
          <el-row class="input-container">
            <span>Nome:</span>
            <el-autocomplete
              class="my-margin"
              v-model="detailData.editable.name"
              placeholder="Selecione um colaborador "
              :fetch-suggestions="querySearch"
              @select="handleChangeColaborator"
            />
          </el-row>
          <el-row class="input-container">
            <span>CPF/CNPJ:</span>
            <el-input
              class="my-margin"
              v-model="detailData.editable.register"
              placeholder="Adicione o registro do colaborador"
              v-mask="mask"
              @input="handleRegisterMask"
            />
          </el-row>
          <el-row class="input-container">
            <span>Função do colaborador:</span>
            <el-input
              class="my-margin"
              v-model="detailData.editable.role"
              placeholder="Relate a função do colaborador"
            />
          </el-row>
          <el-row class="input-container">
            <span>Horas</span>
            <el-input-number
              class="my-margin"
              controls-position="right"
              :min="0"
              v-model="detailData.editable.workedHours"
            />
          </el-row>
          <el-row class="input-container">
            <span>Nível de escolaridade:</span>
            <el-select
              class="my-margin"
              v-model="detailData.editable.schooling"
              placeholder="Selecione o nível de escolaridade"
            >
              <el-option
                v-for="(item, index) in schoolingOptions"
                :key="`schooling-${item}-${index}-option`"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-row>
          <el-row class="input-container">
            <span>Formação:</span>
            <el-input
              class="my-margin"
              v-model="detailData.editable.formation"
              placeholder="Relate a formação do colaborador"
            />
          </el-row>
          <el-row class="input-container">
            <span>Tipo:</span>
            <el-select
              class="my-margin"
              v-model="detailData.editable.apportionType"
              placeholder="Selecione um tipo"
            >
              <el-option
                v-for="(item, index) in typeOptions"
                :key="`type-${item.value}-${index}-option`"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-row>
          <el-row class="input-container">
            <span>Atividade:</span>
            <el-input
              class="my-margin"
              type="textarea"
              maxlength="1000"
              max="1000"
              :rows="5"
              show-word-limit
              placeholder="Relate a atividade do colaborador"
              v-model="detailData.editable.activity"
            />
          </el-row>
        </div>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'
import { handleFormatLabels } from '@/utils/rubrics'

export default {
  name: 'DrawerExternalDetail',
  components: {
    Drawer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    schoolingOptions: {
      type: Array,
      default: () => [],
    },
    collaboratorsExternalList: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
  },
  data() {
    return {
      inputLabels: [],
      detailData: {
        editable: {},
      },
      typeOptions: [
        {
          value: 'direct',
          label: 'Direto',
        },
        {
          value: 'indirect',
          label: 'Indireto',
        },
      ],
      mask: '###.###.###-##',
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },

    formatTableValue(value) {
      return new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
    },
    handleFormatInfo() {
      const data = this.info.rowInfo
      const formatDetail = {
        id: data['id'] || '',
        detailId: data['detailId'] || '',
        isUnion: data['isCorrelate'] || false,
        correlateId: data['correlateId'] || '',
        showInfo: {
          total: {
            value: this.formatTableValue(data['value']),
            label: 'Valor total',
          },
          complement: {
            value: data['complement'] || '',
            label: 'Complemento',
          },
        },
        editable: {
          name: data['name'] || '',
          register: this.handleFormatRegister(data['register']) || '',
          role: data['role'] || '',
          workedHours: data['workedHours'] || '',
          schooling: data['schooling'] || '',
          formation: data['formation'] || '',
          apportionType: data['apportionType'] || '',
          activity: data['activity'] || '',
        },
      }

      this.detailData = formatDetail
    },
    handleDetail() {
      let { editable } = this.detailData

      const isCorrelate = this.info.rowInfo.isCorrelate
      let correlateId = this.info.rowInfo.correlateId || ''
      let ledgerBookIdList = []
      let sendEditable = JSON.parse(JSON.stringify(editable))

      if (isCorrelate) {
        ledgerBookIdList = this.info.rowInfo.ledgerBookList.map(({ id }) => id)
      } else {
        ledgerBookIdList.push(correlateId)
        correlateId = null
      }

      const send = {
        ledgerBookIdList,
        type: this.info.type,
        newCorrelate: {
          ...sendEditable,
          register: sendEditable.register?.replace(/\D/g, ''),
        },
        correlateId,
      }

      this.$emit('detail', { ...send })
    },
    handleChangeColaborator(collaborator) {
      this.detailData.editable.register = collaborator.register
      this.detailData.editable.role = collaborator.role
      this.detailData.editable.schooling = collaborator.schooling
      this.detailData.editable.formation = collaborator.formation
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
    querySearch(queryString, cb) {
      const collaboratorsList = this.collaboratorsExternalList
      let results = queryString
        ? collaboratorsList.filter(this.createFilter(queryString))
        : collaboratorsList
      cb(results)
    },
    createFilter(queryString) {
      return (collaborator) => {
        return (
          collaborator.value
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleRegisterMask(register) {
      const clearRegister = register?.replace(/\D/g, '')

      this.detailData.editable.register =
        this.handleFormatInputRegister(clearRegister)

      this.mask =
        clearRegister?.length <= 11 ? '###.###.###-##' : '##.###.###/####-##'
    },
  },
  computed: {
    getShowInfo() {
      return this.detailData.showInfo
    },
  },
  watch: {
    info: {
      handler() {
        this.handleFormatInfo()
      },
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
