import mixins from '@/mixins'

const tableInnerConfig = {
  correlates1: [
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Conta contábil',
      prop: 'accountStruct',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  correlates2: [
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Conta contábil',
      prop: 'accountStruct',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  books: [
    {
      label: 'Título',
      prop: 'title',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Quantidade',
      prop: 'quantity',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'center',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  external: [
    {
      label: 'Prestador',
      prop: 'name',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'CNPJ',
      prop: 'register',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Função colaborador',
      prop: 'role',
      minWidth: 200,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  direct: [
    {
      label: 'Nome',
      prop: 'name',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'CPF',
      prop: 'cpf',
      minWidth: 150,
      formatter: formatTableCPF,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Função colaborador',
      prop: 'role',
      minWidth: 180,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Formação',
      prop: 'formation',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Escolaridade',
      prop: 'schooling',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Horas',
      prop: 'workedHours',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  indirect: [
    {
      label: 'Nome',
      prop: 'name',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'CPF',
      prop: 'cpf',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Função colaborador',
      prop: 'role',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Formação',
      prop: 'formation',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Escolaridade',
      prop: 'schooling',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Horas',
      prop: 'workedHours',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
  ],
  civil: [
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'CNPJ/CPF',
      prop: 'register',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Obra realizada',
      prop: 'description',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  leasing: [
    {
      label: 'Descrição',
      prop: 'description',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'N° do contrato',
      prop: 'contract',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Quantidade',
      prop: 'quantity',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'N° nota fiscal',
      prop: 'invoice',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
  ],
  equip: [
    {
      label: 'Descrição',
      prop: 'description',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Quantidade',
      prop: 'quantity',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'N° nota fiscal',
      prop: 'invoice',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
    {
      label: 'Classificação (MCT)',
      prop: 'MCT',
      minWidth: 180,
      formatter: formatTableData,
      headerAlign: 'left',
      align: 'left',
    },
  ],
  consumables: [
    {
      label: 'Descrição',
      prop: 'description',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Quantidade',
      prop: 'quantity',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'N° nota fiscal',
      prop: 'invoice',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
  ],
  trips: [
    {
      label: 'Tipo',
      prop: 'expenseType',
      minWidth: 150,
      formatter: formatExpenseType,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
    {
      label: 'Complemento',
      prop: 'complement',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
  ],
  training: [
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
    {
      label: 'Objetivo',
      prop: 'objective',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Assunto',
      prop: 'subject',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Período',
      prop: 'period',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'center',
    },
    {
      label: 'Carga horária',
      prop: 'hours',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'center',
    },
  ],
  services: [
    {
      label: 'Fornecedor',
      prop: 'provider',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'CNPJ/CPF',
      prop: 'register',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'Valor total',
      prop: 'value',
      minWidth: 150,
      formatter: formatTableValue,
      headerAlign: 'center',
      align: 'center',
    },
    {
      label: 'Descrição dos serviços',
      prop: 'description',
      minWidth: 200,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
    {
      label: 'N° nota fiscal',
      prop: 'invoice',
      minWidth: 150,
      formatter: formatTableData,
      headerAlign: 'center',
      align: 'left',
    },
  ],
}

function formatTableValue(row, column, cellValue, index) {
  return mixins.methods.handleFormatValue(cellValue)
}

function formatTableDate(row, column, cellValue, index) {
  return mixins.methods.handleFormatDate(cellValue)
}

function formatTableData(row, column, cellValue, index) {
  return cellValue !== undefined && cellValue.length > 0 ? cellValue : '-'
}

function formatTableCPF(row, column, cellValue, index) {
  return mixins.methods.handleFormatCPF(cellValue)
}

function formatExpenseType(row, column, cellValue, index) {
  const dic = {
    passage: 'Passagens',
    hosting: 'Hospedagens',
    vehiclesLoc: 'Locação de veículos',
    discount: 'Descontos Obtidos viagens',
    others: 'Outros gastos',
  }

  return dic[cellValue]
}

export default tableInnerConfig
