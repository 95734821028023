<template>
  <Drawer
    :visibility="isVisible"
    drawerName="Anexar informações passadas"
    @close="handleClose"
    @save="handleNewAttach"
  >
    <div class="drawer-attach-wrapper">
      <div v-if="selectOptions" class="drawer-attach-container">
        <span class="drawer-attach-description">{{ getDescription }}</span>
        <div v-if="loadindList">
          <el-skeleton animated :rows="9" />
        </div>
        <div v-else>
          <div v-if="selectOptions?.length > 0" class="checkbox__container">
            <el-radio
              class="checkbox__body"
              v-model="selectedTrip"
              :label="null"
              border
            >
              <div class="checkbox__inner">Nenhuma das opções</div>
            </el-radio>
            <div v-for="(option, index) in getOptions" :key="index">
              <el-radio
                class="checkbox__body"
                v-model="selectedTrip"
                :label="option.id"
                border
              >
                <div class="checkbox__inner">
                  <el-row
                    v-for="(details, index) in option?.data"
                    :key="`radio-trip-${index}`"
                  >
                    {{ handleFormatText(details) }}
                  </el-row>
                </div>
              </el-radio>
            </div>
          </div>
          <div v-else>
            Sem informações de viagens deste profissional em meses anteriores.
          </div>
        </div>
      </div>
      <div v-else>
        <span class="drawer-attach-description">
          Para visualizar lançamentos passados informe um profissional na ação
          detalhar.
        </span>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'

export default {
  name: 'DrawerAttachTrips',
  components: {
    Drawer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    loadindList: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTrip: null,
      nullOption: {
        tripId: null,
        correlateId: null,
        ledgerBookId: null,
        isCorrelate: null,
      },
    }
  },
  methods: {
    handleClose() {
      this.selectedTrip = null
      this.$emit('close')
    },
    handleNewAttach() {
      const {
        rowInfo: {
          collabId: collaborator,
          correlateId,
          ledgerBookList,
          isCorrelate: isUnion,
        },
      } = this.info

      const {
        tripId,
        correlateId: correlateIdSelectedTrip,
        ledgerBookId: ledgerBookIdSelectedTrip,
      } = this.selectedTrip !== null
        ? this.getOptions.find(({ id }) => id === this.selectedTrip)
        : this.nullOption

      const correlatesIdList = [
        correlateIdSelectedTrip,
        isUnion ? correlateId : '',
      ].filter((element) => element)

      const ledgerBooksIdList = [
        ledgerBookIdSelectedTrip,
        ...(isUnion ? '' : ledgerBookList.map(({ id }) => id)),
      ].filter((element) => element)

      const sendInfo = {
        tripId,
        correlatesIdList,
        ledgerBooksIdList,
        collaborator,
      }
      this.$emit('attachTrip', sendInfo)
    },
    handleFormatText({ date, description, collaborator, value }) {
      return `Data: ${this.handleFormatDate(date)},
      Descrição: ${description},
      Colaborador: ${collaborator},
      Valor: ${this.handleFormatValue(value)}`
    },
  },
  computed: {
    getDescription() {
      return `Selecione informações passadas de ${
        this.info.rowInfo?.professional.length > 0
          ? this.info.rowInfo.professional
          : 'profissional não informado'
      } para que sejam visualizadas no relatório`
    },
    getOptions() {
      const options = this.selectOptions?.map((option, index) => {
        let isSelected = false

        if (this.info.rowInfo?.tripsGroupId === option?.tripId) {
          this.selectedTrip = index
          isSelected = true
        }

        return {
          isSelected,
          id: index,
          ...option,
        }
      })
      return options
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
