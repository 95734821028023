import api from '../instance'

export function fetchTripList(collaboratorId, month, costCenter) {
  return api.get(
    `/trips?collaborator=${collaboratorId}&month=${month}&costCenter=${costCenter}`
  )
}

export function fetchAttachTripList(data) {
  return api.post('/trips', data)
}
