import api from '../instance'

export function getExpenditureByTag(financialReportId, rubric) {
  return api.get(
    `/details?financialReportId=${financialReportId}&rubric=${rubric}`
  )
}

export function createNewUnion(newCorrelate, ledgerBookIdList) {
  return api.post('/details', {
    newCorrelate,
    ledgerBookIdList,
  })
}

export function deleteUnion(correlateId) {
  return api.delete('/details', { data: { correlateId } })
}

export function detailEdit({
  ledgerBookIdList,
  type,
  newCorrelate,
  correlateId,
}) {
  return api.put('/details', {
    ledgerBookIdList,
    type,
    newCorrelate,
    correlateId,
  })
}
