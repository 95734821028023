<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      :isBtnDisabled="getBtnStatus"
      drawerName="Ajuste de valor do relatório"
      @close="handleClose"
      @save="handleNewAdjustment"
    >
      <div class="drawer-detail">
        <span> Informações do lançamento: </span>
        <div div class="drawer-detail__header">
          <el-row
            v-for="(info, index) in handleShowInfo"
            :key="`drawerInfo-${info.label}-${index}`"
          >
            <span>{{ info.label }}</span>
            {{ info.value }}
          </el-row>
        </div>
        <div class="column">
          <span>Selecione uma despesa</span>
          <el-select
            v-model="selectDispenseType"
            id="dispenseType"
            clearable
            filterable
            @change="handleChangeOption"
          >
            <el-option
              v-for="(element, index) in dispenseOptions"
              :key="`dispenseType-${element.label}-${index}`"
              :label="element.label"
              :value="element.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="selectDispenseType.length > 0" class="values-container">
          <span>{{ getLabel }}</span>
          <el-row>
            <el-col :span="8">
              <p>Valor relatório</p>
              {{
                handleFormatValue(valuesInfo[selectDispenseType].collabValues)
              }}
            </el-col>
            <el-col :span="8">
              <p>Valor razão</p>
              {{
                handleFormatValue(
                  valuesInfo[selectDispenseType].ledgerBookValue
                )
              }}
            </el-col>
            <el-col :span="8">
              <p>Diferença</p>
              {{ handleFormatValue(valuesInfo[selectDispenseType].difference) }}
            </el-col>
          </el-row>
          <div class="column input-value">
            Atualizar valor
            <Money
              class="my-margin el-input__inner"
              v-bind="money"
              v-model="adjustmentValue"
            ></Money>
          </div>
        </div>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'
import { handleGetDetailFormat, handleFormatLabels } from '@/utils/rubrics'
import { Money } from 'v-money'

export default {
  name: 'DrawerAdjustment',
  components: {
    Drawer,
    Money,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    rubricsDetails: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
  },
  data() {
    return {
      type: '',
      inputLabels: [],
      detailData: {
        editable: {},
      },
      selectDispenseType: '',
      adjustmentValue: 0,
      valuesInfo: {},
      dispenseOptions: [
        { value: 'sheet', label: 'Salário' },
        { value: 'prizes', label: 'Premiação' },
        { value: 'charges', label: 'Encargos' },
        { value: 'benefits', label: 'Benefícios' },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleNewAdjustment() {
      let type = this.selectDispenseType
      let sendValue = {
        data: {},
        rubric: this.type,
        collabValueId: this.collabValueId,
      }
      sendValue.data[type] = this.adjustmentValue
      this.$emit('send', { sendValue, rubric: this.type })
    },
    handleChangeOption() {
      this.adjustmentValue = 0
    },
    handleFormatInfo() {
      const formatDetail = handleGetDetailFormat(this.info.rowInfo, this.type)

      this.valuesInfo = {
        sheet: this.info.rowInfo.sheet,
        prizes: this.info.rowInfo.prizes,
        charges: this.info.rowInfo.charges,
        benefits: this.info.rowInfo.benefits,
      }

      this.detailData = formatDetail
      this.inputLabels = Object.keys(formatDetail.editable)
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
  },
  computed: {
    handleShowInfo() {
      return this.detailData.showInfoAdjustment
    },
    getLabel() {
      const dic = {
        sheet: 'Salário',
        prizes: 'Premiação',
        charges: 'Encargos',
        benefits: 'Benefícios',
      }
      return dic[this.selectDispenseType]
    },
    getBtnStatus() {
      return this.selectDispenseType.length === 0
    },
  },
  watch: {
    info() {
      this.type = this.info.type
      this.collabValueId = this.info.rowInfo.collabValueId
      this.selectDispenseType = ''
      this.handleFormatInfo()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
