<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      drawerName="Detalhamento do lançamento"
      @close="handleClose"
      @save="handleNewDetail"
    >
      <div>
        <el-row class="info-header-container">
          <span class="info-header__title">
            Organize informações do colaborador
          </span>
          <div class="info-header-info">
            <span>{{ `Salário: ${handleFormatValue(valuesInfo.sheet)}` }}</span>
            <span>{{
              `Premiação: ${handleFormatValue(valuesInfo.prizes)}`
            }}</span>
            <span>{{
              `Encargos: ${handleFormatValue(valuesInfo.charges)}`
            }}</span>
            <span>{{
              `Benefícios: ${handleFormatValue(valuesInfo.benefits)}`
            }}</span>
            <span>{{ `Total: ${handleFormatValue(valuesInfo.total)}` }}</span>
          </div>
        </el-row>
        <el-row class="inputs-container">
          <el-row class="inputs-container__row">
            <span>Tipo de despesa</span>
            <el-select
              v-model="inputData.expenseType"
              placeholder="Selecionar tipo de despesa"
              :disabled="isUnion"
            >
              <el-option
                v-for="item in expenseTypeOptions"
                :key="`select-expenseType-type-${item.label}`"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-row>
          <el-row class="inputs-container__row">
            <span>Nome </span>
            <el-select
              v-model="collaboratorId"
              placeholder="Selecionar colaborador"
              @change="handleChangeCollaborator"
            >
              <el-option
                v-for="item in collaborators"
                :key="`select-colaborator-${item.id}`"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-row>
          <el-row class="inputs-container__row">
            <span>CPF</span>
            <el-input
              v-model="inputData.cpf"
              disabled
              v-mask="'###.###.###-##'"
            />
          </el-row>
          <el-row class="inputs-container__row">
            <span>Função do colaborador</span>
            <el-input v-model="inputData.role" disabled />
          </el-row>
          <el-row class="inputs-container__row">
            <span>Formação</span>
            <el-input
              v-model="inputData.formation"
              placeholder="Informe a formação do colaborador"
            />
          </el-row>
          <el-row class="inputs-container__row">
            <span>Escolaridade </span>
            <el-select
              v-model="inputData.schooling"
              placeholder="Selecionar nível de escolaridade"
            >
              <el-option
                v-for="item in schoolingOptions"
                :key="`select-schooling-${item}`"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-row>
          <el-row class="inputs-container__row">
            <span>Horas trabalhadas </span>
            <el-input-number
              v-model="inputData.workedHours"
              controls-position="right"
              :min="0"
            />
          </el-row>
          <el-row class="inputs-container__row">
            <span>Atividade </span>
            <el-input
              type="textarea"
              v-model="inputData.activity"
              rows="5"
              show-word-limit
              maxlength="1000"
              placeholder="Atividade do colaborador..."
            />
          </el-row>
        </el-row>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'

export default {
  name: 'DrawerRHDetail',
  components: {
    Drawer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    rubricsDetails: {
      type: Object,
      default: () => {},
    },
    collaborators: {
      type: Array,
      default: () => [],
    },
    schoolingOptions: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
  },
  data() {
    return {
      inputData: {
        name: '',
        expenseType: '',
        cpf: '',
        role: '',
        formation: '',
        schooling: '',
        workedHours: '',
        activity: '',
      },
      collaboratorId: '',
      colaboratorOptions: [],
      expenseTypeOptions: [
        { value: 'sheet', label: 'Salário' },
        { value: 'prizes', label: 'Premiação' },
        { value: 'charges', label: 'Encargo' },
        { value: 'benefits', label: 'Benefício' },
      ],
      valuesInfo: {
        sheet: 0,
        prizes: 0,
        charges: 0,
        benefits: 0,
        total: 0,
      },
      isUnion: false,
      isAlerted: false,
    }
  },
  methods: {
    handleClose() {
      this.isAlerted = false
      this.$emit('close')
    },
    handleNewDetail() {
      const { correlateId, ledgerBookList } = this.info.rowInfo
      const ledgerBookIdList = ledgerBookList.map(({ id }) => id)
      this.inputData['collaboratorId'] = this.collaboratorId
      this.inputData['collabValueId'] =
        this.info.rowInfo.name === this.inputData.name
          ? this.info.rowInfo.collabValueId
          : ''
      this.inputData.cpf = this.inputData.cpf.replace(/\D/g, '')
      this.$emit('send', {
        newCorrelate: this.inputData,
        type: this.type,
        correlateId: this.isUnion ? correlateId : '',
        ledgerBookIdList,
      })
    },
    handleChangeCollaborator() {
      const { cpf, role, name } = this.collaborators.find(
        (element) => element.id === this.collaboratorId
      )

      const { cpf: originalCPF, name: originalName } = this.info.rowInfo

      if (originalCPF && originalName && !this.isAlerted) {
        this.$alert(
          'Ao mudar o colaborador do lançamento para um outro colaborador já detalhado, o sistema pode voltar a exibir informações de folha do primeiro colaborador na lista! deseja continuar com a ação?',
          'Colaborador já está detalhado no sistema',
          {
            confirmButtonText: 'Continuar',
            confirmButtonClass: 'primary-button',
          }
        ).then(() => {
          this.isAlerted = true
        })
      }

      this.inputData.name = name
      this.inputData.cpf = cpf
      this.inputData.role = role
    },
    handleFormatInfo() {
      this.valuesInfo = {
        sheet: this.info.rowInfo.sheet.collabValues,
        prizes: this.info.rowInfo.prizes.collabValues,
        charges: this.info.rowInfo.charges.collabValues,
        benefits: this.info.rowInfo.benefits.collabValues,
        total: this.info.rowInfo.total.collabValues,
      }

      const {
        name,
        expenseType,
        cpf,
        role,
        formation,
        schooling,
        workedHours,
        activity,
        isCorrelate,
      } = this.info.rowInfo

      this.inputData = {
        name,
        expenseType,
        cpf,
        role,
        formation,
        schooling,
        workedHours,
        activity,
      }

      const { id } =
        this.collaborators?.find(
          (element) => element?.name === name && element?.cpf === cpf
        ) || ''

      this.collaboratorId = id

      this.isUnion = isCorrelate
    },
  },
  computed: {
    handleShowInfo() {
      return this.detailData.showInfoAdjustment
    },
  },
  watch: {
    info() {
      this.type = this.info.type
      this.collabValueId = this.info.rowInfo.collabValueId
      this.handleFormatInfo()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
