<template>
  <el-main id="details-page">
    <HeaderInfo :info="headerInfo" />
    <el-tabs
      class="content-container"
      :value="getActiveTab"
      tab-position="left"
      @tab-click="handleTabClick"
    >
      <el-tab-pane label="Equipamentos" class="pane-container" name="equip">
        <MultGeneralExpenditure
          v-if="activeTab === 'equip'"
          :info="getEquipData"
          :showFilter="false"
          :loading="loading"
          @detail="handleDetail"
          @union="handleUnion"
          @undo="handleUndoUnion"
          @edit="handleEdit"
          @transfer="handleTransfer"
          @leasing="
            (row) => goToEntriesRHPage(row, 'leasing', 'EntriesLeasingPage')
          "
          id="mult"
        />
      </el-tab-pane>
      <el-tab-pane label="Obras civis" class="pane-container" name="civil">
        <GeneralExpenditure
          v-if="activeTab === 'civil'"
          id="civil"
          type="civil"
          title="Obras civis"
          :loading="loading"
          :info="rubricsData.civil"
          :totalValue="rubricsTotals.civil.ledgerBook"
          @detail="handleDetail"
          @transfer="handleTransfer"
          @union="handleUnion"
          @undo="handleUndoUnion"
        />
      </el-tab-pane>
      <el-tab-pane label="RH" class="pane-container" name="rh">
        <RH
          :costCenterInformations="headerInfo"
          :propInfo="getRHData"
          :propTotals="getRhTotals"
          :loading="loading"
          :frId="frId"
          v-if="activeTab === 'rh'"
          id="RH"
          @detail="handleDetailRH"
          @transfer="handleTransferRH"
          @union="handleUnion"
          @undo="handleUndoUnion"
          @adjustment="handleAdjustment"
          @rateio="handleRateio"
          @onEntries="
            (row) => goToEntriesRHPage(row, 'external', 'EntriesRHPage')
          "
        />
      </el-tab-pane>
      <el-tab-pane label="Livros" class="pane-container" name="books">
        <GeneralExpenditure
          v-if="activeTab === 'books'"
          id="book"
          type="books"
          title="Livros e periódicos"
          :loading="loading"
          :totalValue="rubricsTotals.books.ledgerBook"
          :info="rubricsData.books"
          @detail="handleDetail"
          @transfer="handleTransfer"
          @union="handleUnion"
          @undo="handleUndoUnion"
        />
      </el-tab-pane>
      <el-tab-pane label="M. consumo" class="pane-container" name="consumables">
        <GeneralExpenditure
          v-if="activeTab === 'consumables'"
          id="consumables"
          type="consumables"
          title="Materiais de consumo"
          :loading="loading"
          :info="rubricsData.consumables"
          :totalValue="rubricsTotals.consumables.ledgerBook"
          @detail="handleDetail"
          @transfer="handleTransfer"
          @union="handleUnion"
          @undo="handleUndoUnion"
        />
      </el-tab-pane>
      <el-tab-pane label="Viagens" class="pane-container" name="trips">
        <GeneralExpenditure
          v-if="activeTab === 'trips'"
          id="trips"
          type="trips"
          title="Viagens"
          :loading="loading"
          :info="rubricsData.trips"
          :totalValue="rubricsTotals.trips.ledgerBook"
          @detail="handleDetail"
          @transfer="handleTransfer"
          @union="handleUnion"
          @undo="handleUndoUnion"
          @attach="handleAttach"
        />
      </el-tab-pane>
      <el-tab-pane label="Treinamento" class="pane-container" name="training">
        <GeneralExpenditure
          v-if="activeTab === 'training'"
          id="training"
          type="training"
          title="Treinamentos"
          :loading="loading"
          :info="rubricsData.training"
          :totalValue="rubricsTotals.training.ledgerBook"
          @detail="handleDetail"
          @transfer="handleTransfer"
          @union="handleUnion"
          @undo="handleUndoUnion"
          @onEntries="
            (row) => goToEntriesRHPage(row, 'training', 'EntriesTrainingsPage')
          "
        />
      </el-tab-pane>
      <el-tab-pane
        label="Serviço de terc."
        class="pane-container"
        name="services"
      >
        <GeneralExpenditure
          v-if="activeTab === 'services'"
          id="services"
          type="services"
          title="Serviço de terceiros"
          :loading="loading"
          :info="rubricsData.services"
          :totalValue="rubricsTotals.services.ledgerBook"
          @detail="handleDetail"
          @transfer="handleTransfer"
          @union="handleUnion"
          @undo="handleUndoUnion"
        />
      </el-tab-pane>
      <el-tab-pane label="Correlatos" class="pane-container" name="correlates">
        <MultGeneralExpenditure
          v-if="activeTab === 'correlates'"
          :loading="loading"
          :info="getCorrelatesData"
          :filters="filterCorrelates"
          :showFilter="true"
          filterProp="accountStruct"
          @detail="handleDetail"
          @union="handleUnion"
          @undo="handleUndoUnion"
          @edit="handleEdit"
          @transfer="handleTransfer"
          id="mult"
        />
      </el-tab-pane>
    </el-tabs>
    <DrawerActions
      :isVisible="isDrawerVisible"
      :info="detailInfo"
      :rubricsDetails="rubricsDetails"
      :action="drawerAction"
      :professionalOptions="getCollabList"
      :schoolingOptions="schoolingOptions"
      :providerOptions="providerOptions"
      :accountStructOptions="filterCorrelates"
      @close="handleCloseDrawer"
      @send="handleSendTransfer"
      @detail="handleSendDetail"
      @transfer="handleSendTransfer"
      @union="handleSendUnion"
    />
    <DrawerRHTransfer
      :isVisible="isDrawerTransferRHVisible"
      :info="detailInfo"
      :rubricsDetails="rubricsDetails"
      :professionalOptions="getCollabList"
      :schoolingOptions="schoolingOptions"
      :providerOptions="providerOptions"
      :accountStructOptions="filterCorrelates"
      :collaborators="collaborators"
      @close="handleCloseDrawer"
      @transfer="handleConfirmTransfer"
    />
    <DrawerAdjustment
      :isVisible="isAdjustmentVisible"
      :info="adjustmentInfo"
      :rubricsDetails="rubricsDetails"
      @close="handleCloseDrawer"
      @send="handleNewAdjustment"
    />
    <DrawerRHDetail
      :isVisible="isDetailRHVisible"
      :info="detailData"
      :rubricsDetails="rubricsDetails"
      :collaborators="collaborators"
      :schoolingOptions="schoolingOptions"
      @close="handleCloseDrawer"
      @send="handleSendDetail"
    >
    </DrawerRHDetail>
    <DrawerRHRateio
      :isVisible="isDrawerRateioVisible"
      :info="detailData"
      @close="handleCloseDrawer"
      @submit="handleSubmitRateio"
    >
    </DrawerRHRateio>
    <DrawerAttachTrips
      :isVisible="isDrawerAttachVisible"
      :info="tripsData"
      :loadindList="attachLoading"
      :selectOptions="tripsList"
      @close="handleCloseDrawerAttach"
      @attachTrip="handleAttachTrip"
    />
    <DrawerExternalDetail
      :isVisible="isDrawerExternalVisible"
      :info="detailInfo"
      :selectOptions="tripsList"
      :schoolingOptions="schoolingOptions"
      :collaboratorsExternalList="getExternalCollaborators"
      @close="handleCloseDrawerExternal"
      @detail="handleSendDetail"
    />
    <DrawerTransfer
      :isVisible="showDrawerTransfer"
      :info="detailInfo"
      :rubricsDetails="rubricsDetails"
      :professionalOptions="getCollabList"
      :schoolingOptions="schoolingOptions"
      :providerOptions="providerOptions"
      :accountStructOptions="filterCorrelates"
      :collaborators="collaborators"
      @close="handleCloseDrawerTransfer"
      @transfer="handleConfirmTransfer"
    />
  </el-main>
</template>

<script>
import {
  createNewUnion,
  deleteUnion,
  detailEdit,
  getExpenditureByTag,
} from '@/service/details'
import { getRubricsDetails, transferRubric } from '@/service/rubrics'
import { fetchAllCollaborators } from '@/service/allCollaborators'
import { getProjectDetails } from '@/service/monthlyProjectDetails'
import { newAdjustment } from '@/service/adjustment'
import { fetchSchooling } from '@/service/schooling'
import { fetchAdjustApportionment } from '@/service/apportionment'
import { getProviders } from '@/service/providers'
import HeaderInfo from './Partials/HeaderInfo/HeaderInfo.vue'
import GeneralExpenditure from './Partials/GeneralExpenditure/GeneralExpenditure.vue'
import MultGeneralExpenditure from './Partials/MultGeneralExpenditure/MultGeneralExpenditure.vue'
import DrawerActions from './Partials/DrawerActions/DrawerActions.vue'
import DrawerAdjustment from './Partials/DrawerAdjustment/DrawerAdjustment.vue'
import DrawerRHTransfer from './Partials/DrawerRHTransfer/DrawerRHTransfer.vue'
import DrawerRHRateio from './Partials/DrawerRHRateio/DrawerRHRateio.vue'
import DrawerAttachTrips from './Partials/DrawerAttachTrips/DrawerAttachTrips.vue'
import DrawerExternalDetail from './Partials/DrawerExternalDetail/DrawerExternalDetail.vue'
import DrawerTransfer from './Partials/DrawerTransfer/DrawerTransfer.vue'
import RH from './Partials/RH/RH.vue'
import DrawerRHDetail from './Partials/DrawerRHDetail/DrawerRHDetail.vue'
import { fetchLegalPersons } from '@/service/legalPerson'
import { fetchTripList, fetchAttachTripList } from '@/service/trips'
import { organizeData } from '@/utils/rubrics'
import { NOT_ALLOCATED } from '../../constants/rubricsConstants'
import { handleFormatLabels } from '@/utils/rubrics'

export default {
  name: 'Details',
  data() {
    return {
      frId: '',
      activeTab: '',
      drawerAction: 'transfer',
      detailInfo: {},
      adjustmentInfo: {},
      schoolingOptions: [],
      providerOptions: [],
      detailData: {},
      loading: true,
      headerInfo: { centerCostCode: null, startDay: '' },
      rubricsData: {
        equip: [],
        civil: [],
        books: [],
        trips: [],
        training: [],
        services: [],
        consumables: [],
        correlates1: [],
        correlates2: [],
        direct: [],
        indirect: [],
        external: [],
        leasing: [],
        base: [],
        others: [],
        othervinc: [],
        charges: [],
        benefits: [],
      },
      rubricsTotals: {
        direct: {},
        indirect: {},
        external: {},
        correlates1: {},
        correlates2: {},
        equip: {},
        civil: {},
        books: {},
        services: {},
        training: {},
        trips: {},
        consumables: {},
        leasing: {},
        base: {},
        others: {},
        othervinc: {},
        charges: {},
        benefits: {},
      },
      rubricsDetails: {},
      filterCorrelates: {
        correlates1: [
          'Alugueis e condomínios',
          'Aluguel de Equipamento',
          'Energia elétrica',
          'Água e esgoto',
          'Telefones',
          'Provedores de acesso',
          'Seguro',
          'IPTU',
          'Desconto Obtidos outros',
          'Manutenção das instalações - diversos',
          'Limpeza e Conservação',
          'Segurança e Vigilância',
          'Outros Serviços Contratados',
          'Material de Limpeza e Conservação',
          'Provedores de Email',
          'Manutenção de Equipamentos de Uso',
        ],
        correlates2: ['CPMF', 'Outros impostos'],
      },
      collaborators: [],
      isDrawerVisible: false,
      isAdjustmentVisible: false,
      isDetailRHVisible: false,
      isDrawerTransferRHVisible: false,
      isDrawerAttachVisible: false,
      isDrawerExternalVisible: false,
      isDrawerRateioVisible: false,
      showDrawerTransfer: false,
      collaboratorsList: [],
      legalPersonList: [],
      externalLegalPersonList: [],
      externalCollaboratorsList: [],
      tripsData: {},
      tripsList: [],
      attachLoading: false,
    }
  },
  components: {
    HeaderInfo,
    MultGeneralExpenditure,
    GeneralExpenditure,
    DrawerActions,
    DrawerAdjustment,
    RH,
    DrawerRHDetail,
    DrawerRHRateio,
    DrawerAttachTrips,
    DrawerRHTransfer,
    DrawerExternalDetail,
    DrawerTransfer,
  },
  computed: {
    getActiveTab() {
      return this.activeTab
    },
    getExternalCollaborators() {
      return this.legalPersonList
    },
    getCorrelatesData() {
      return {
        data: {
          correlates1: this.rubricsData.correlates1,
          correlates2: this.rubricsData.correlates2,
        },
        totals: {
          correlates1: this.rubricsTotals.correlates1,
          correlates2: this.rubricsTotals.correlates2,
        },
      }
    },
    getCollabList() {
      return this.handleSortByName(
        [...this.collaboratorsList, ...this.legalPersonList].filter(
          (collab) => collab?.name
        )
      )
    },
    getEquipData() {
      return {
        data: {
          equip: this.rubricsData.equip,
          leasing: this.rubricsData.leasing,
        },
        totals: {
          equip: this.rubricsTotals.equip,
          leasing: this.rubricsTotals.leasing,
        },
      }
    },
    getRHData() {
      return {
        indirect: this.rubricsData.indirect,
        direct: this.rubricsData.direct,
        external: this.rubricsData.external,
        notAllocated: NOT_ALLOCATED.reduce(
          (acc, curr) =>
            acc.concat(
              this.rubricsData[curr].map((e) => ({ ...e, rubricType: curr }))
            ),
          []
        ),
      }
    },
    getRhTotals() {
      return {
        indirect: this.rubricsTotals?.indirect || {},
        direct: this.rubricsTotals?.direct || {},
        external: this.rubricsTotals?.external || {},
        notAllocated:
          {
            ledgerBook: NOT_ALLOCATED.reduce(
              (acc, curr) => acc + (this.rubricsTotals[curr]?.ledgerBook || 0),
              0
            ),
          } || {},
      }
    },
  },
  methods: {
    goToEntriesRHPage(row, rubricType, rubricName) {
      this.$router.push({
        name: rubricName,
        params: {
          rubricType: rubricType,
          projectId: this.frId,
          providerId: row.rowInfo.correlateId,
          paramIdType: row.rowInfo.isCorrelate ? 'correlate' : 'ledgerBook',
          costcenterCode: this.headerInfo.centerCostCode,
        },
      })
    },
    handleTabClick({ name }) {
      this.activeTab = name
      this.$router.push({
        name: 'Details',
        params: {
          id: this.frId,
          rubric: name,
        },
      })
    },
    handleCloseDrawer() {
      this.isDrawerVisible = false
      this.isAdjustmentVisible = false
      this.isDetailRHVisible = false
      this.isDrawerTransferRHVisible = false
      this.isDrawerExternalVisible = false
      this.isDrawerRateioVisible = false
    },
    handleCloseDrawerAttach() {
      this.isDrawerAttachVisible = false
      this.tripsList = []
      this.tripsData = {}
    },
    handleCloseDrawerExternal() {
      this.isDrawerExternalVisible = false
    },
    handleCloseDrawerTransfer() {
      this.showDrawerTransfer = false
    },
    handleDetail(detailInfo) {
      this.drawerAction = 'detail'
      this.detailInfo = detailInfo
      this.isDrawerVisible = true
    },
    handleDetailRH(detailInfo) {
      if (detailInfo.type === 'external') {
        this.detailInfo = { ...detailInfo }
        this.isDrawerExternalVisible = true
      } else {
        this.detailData = { ...detailInfo }
        this.isDetailRHVisible = true
      }
    },
    handleTransfer(detailInfo) {
      this.detailInfo = detailInfo
      this.showDrawerTransfer = true
    },
    handleRateio(detailInfo) {
      this.detailData = { ...detailInfo }
      this.isDrawerRateioVisible = true
    },
    handleTransferRH(detailInfo) {
      const rhTypes = ['direct', 'indirect']
      this.detailInfo = detailInfo
      if (rhTypes.includes(detailInfo.type)) {
        this.isDrawerTransferRHVisible = true
      } else {
        this.showDrawerTransfer = true
      }
    },
    handleUnion(detailInfo) {
      this.drawerAction = 'union'
      this.detailInfo = detailInfo
      this.isDrawerVisible = true
    },
    handleAdjustment(detailInfo) {
      this.adjustmentInfo = detailInfo
      this.isAdjustmentVisible = true
    },
    handleAttach(detailInfo) {
      this.tripsData = detailInfo
      const {
        rowInfo: { collabId },
      } = detailInfo
      collabId ? this.fetchTripList(collabId) : (this.tripsList = null)
      this.isDrawerAttachVisible = true
    },
    async handleSubmitRateio({ info }) {
      let loading = this.$loading({
        lock: true,
        text: 'Ajustando rateio...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const sendInfo = {
          distributions: info.distributions,
          collabValueId: info.rowInfo?.collabValueId,
          rubric: info?.type,
          financialReportId: this.frId,
          costCenterCode: this.headerInfo?.centerCostCode,
          correlateId: info?.correlateId,
          ledgerBookIdList: info?.ledgerBookIdList,
        }
        const data = await fetchAdjustApportionment(sendInfo)
        if (data) {
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Rateio ajustado com sucesso!',
            duration: 5000,
          })
          this.handleCloseDrawer()
          await this.handleGetExpenditure([info?.type])
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response?.data || 'Falha ao ajustar rateio.',
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async handleAttachTrip(sendInfo) {
      let loading = this.$loading({
        lock: true,
        text: 'Adicionando viagem...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const data = await fetchAttachTripList({
          ...sendInfo,
          costCenter: this.headerInfo.centerCostCode,
        })
        if (data) {
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Operação realizada com sucesso!',
            duration: 5000,
          })
          this.handleCloseDrawerAttach()
          await this.handleGetExpenditure(['trips'])
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response?.data || 'Falha ao realizar detalhamento.',
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async fetchCollaborators() {
      try {
        const { data } = await fetchAllCollaborators()
        if (data) {
          this.collaboratorsList = data.map((e) => {
            return { ...e, value: e.id, label: e.name }
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async fetchLegalPerson() {
      try {
        const { data } = await fetchLegalPersons(this.headerInfo.centerCostCode)
        if (data) {
          this.legalPersonList = data.map((e) => {
            return { ...e, value: e.name, label: e.name }
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async fetchTripList(collabId) {
      try {
        this.attachLoading = true
        const { data } = await fetchTripList(
          collabId,
          this.headerInfo.startDay,
          this.headerInfo.centerCostCode
        )
        if (data) {
          this.tripsList = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        this.attachLoading = false
      }
    },
    async handleEdit({ sendData, onEnd, type }) {
      let loading = this.$loading({
        lock: true,
        text: 'Editando lançamento...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const res = await detailEdit(sendData)
        if (res) {
          this.$message({
            showClose: true,
            type: 'success',
            sshowClose: true,
            message: 'Edição realizada com sucesso!',
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data || 'Falha ao editar lançamento.',
          duration: 5000,
        })
      } finally {
        await this.handleGetExpenditure([type])
        await onEnd()
        loading.close()
      }
    },
    async handleNewCorrelate({ newCorrelate, ledgerBookIdList, onEnd, type }) {
      let loading = this.$loading({
        lock: true,
        text: 'Criando união...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const res = await createNewUnion(newCorrelate, ledgerBookIdList)
        if (res) {
          this.$message({
            showClose: true,
            type: 'success',
            sshowClose: true,
            message: 'União realizada com sucesso!',
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data || 'Falha ao unir lançamentos.',
          duration: 5000,
        })
      } finally {
        await onEnd()
        await this.handleGetExpenditure([type])
        loading.close()
      }
    },
    async handleUndoUnion({ correlateId, rubric }) {
      let loading = this.$loading({
        lock: true,
        text: 'Desfazendo união...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const res = await deleteUnion(correlateId)
        if (res) {
          this.$message({
            showClose: true,
            type: 'success',
            sshowClose: true,
            message: 'União desfeita com sucesso!',
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data || 'Falha ao desfazer união.',
          duration: 5000,
        })
      } finally {
        await this.handleGetExpenditure([rubric])
        loading.close()
      }
    },
    async handleSendDetail({
      correlateId,
      type,
      ledgerBookIdList,
      newCorrelate,
    }) {
      let loading = this.$loading({
        lock: true,
        text: 'Detalhando lançamento...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        let res = false
        res = await detailEdit({
          correlateId,
          type,
          ledgerBookIdList,
          newCorrelate: { ...newCorrelate, financialReportId: this.frId || '' },
        })
        if (res) {
          this.handleGetExpenditure([type])
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Operação realizada com sucesso!',
            duration: 5000,
          })
        }
        this.handleCloseDrawer()
        this.handleGetProviderOptions()
        this.fetchLegalPerson()
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data || 'Falha ao realizar detalhamento.',
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async handleSendUnion({ newCorrelate, ledgerBookIdList, rubric }) {
      newCorrelate['financialReportId'] = this.frId
      let loading = this.$loading({
        lock: true,
        text: 'Unindo lançamentos...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const res = await createNewUnion(newCorrelate, ledgerBookIdList)
        if (res) {
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Operação realizada com sucesso!',
            duration: 5000,
          })
          await this.handleGetExpenditure([rubric])
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data || 'Falha ao unir lançamentos.',
          duration: 5000,
        })
      } finally {
        this.isDrawerVisible = false
        loading.close()
      }
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
    handleConfirmTransfer(transferInfo) {
      this.$confirm(
        `Deseja transferir o lançamento de 
        ${this.handleFormatLabel(transferInfo.type)} 
        para ${this.handleFormatLabel(transferInfo.sendType)}? 
        ${
          NOT_ALLOCATED.includes(transferInfo.sendType)
            ? 'Ao fazer esta ação, você perdera informações detalhadas, lançamentos e uniões. Deseja continuar com a ação?'
            : ''
        }`,
        'Confirmação de transferência',
        {
          confirmButtonText: 'Transferir',
          cancelButtonText: 'Cancelar',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
          type: '',
        }
      )
        .then(() => {
          this.handleSendTransfer(transferInfo)
        })
        .catch(() => {})
    },
    async handleSendTransfer({
      idList,
      details,
      sendType,
      type,
      rubricId,
      collabValueId = '',
      correlateId,
    }) {
      let loading = this.$loading({
        lock: true,
        text: 'Transferindo lançamento...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        await transferRubric({
          idList,
          details,
          rubricId,
          collabValueId,
          correlateId,
          financialReportId: this.frId,
        })
        await this.handleGetExpenditure([type, sendType])
        this.$message({
          showClose: true,
          type: 'success',
          message: 'Operação realizada com sucesso!',
          duration: 5000,
        })
        this.handleCloseDrawerTransfer()
        this.handleCloseDrawer()
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data || 'Falha ao tranferir lançamento.',
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async handleNewAdjustment({ sendValue, rubric }) {
      let loading = this.$loading({
        lock: true,
        text: 'Ajustando valor do lançamento...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const { data } = await newAdjustment(sendValue)
        if (data) {
          await this.handleGetExpenditure([rubric])
          this.isAdjustmentVisible = false
          this.$message({
            showClose: true,
            type: 'success',
            message: 'Operação realizada com sucesso!',
            duration: 5000,
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        loading.close()
      }
    },
    async handleGetExpenditure(tags) {
      try {
        const { data } = await getExpenditureByTag(this.frId, tags.toString())
        if (data) {
          tags.forEach((tag) => {
            this.rubricsData[tag] = organizeData(tag, data[tag]?.data) || []
            this.rubricsTotals[tag] =
              data[tag]?.totals || data[tag]?.total || {}
          })
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error.response.data,
          duration: 5000,
          sshowClose: true,
        })
      }
    },
    async handleProjectDetails() {
      try {
        const { data } = await getProjectDetails(this.frId)
        if (data) {
          const { centerCostCode, projectName, referenceDay } = data
          const [start, end] = referenceDay
          this.headerInfo = {
            centerCostCode,
            projectName,
            startDay: new Date(start),
            endDay: new Date(end),
          }
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async handleGetSchoolingOptions() {
      try {
        const { data } = await fetchSchooling()
        if (data) {
          this.schoolingOptions = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async handleGetProviderOptions() {
      try {
        const { data } = await getProviders()
        if (data) {
          this.providerOptions = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async handleCollaborators() {
      try {
        const { data } = await fetchAllCollaborators()
        if (data) {
          this.collaborators = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    async handleRubricsDetails() {
      try {
        const { data } = await getRubricsDetails()
        if (data) {
          this.rubricsDetails = data
        }
      } catch (error) {
        this.$message({
          showClose: true,
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
  },
  async created() {
    const rubricsFullList = [
      'equip',
      'civil',
      'books',
      'trips',
      'training',
      'services',
      'consumables',
      'correlates1',
      'correlates2',
      'direct',
      'indirect',
      'external',
      'leasing',
      ...NOT_ALLOCATED,
    ]
    this.frId = this.$route.params.id
    this.activeTab = this.$route.params.rubric
    await this.handleGetSchoolingOptions()
    await this.handleGetProviderOptions()
    await this.handleProjectDetails()
    await this.handleCollaborators()
    await this.handleGetExpenditure(rubricsFullList)
    await this.handleRubricsDetails()
    this.fetchLegalPerson()
    this.fetchCollaborators()
    this.loading = false
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
