<template>
  <el-main class="rubric-container">
    <div class="rubric-container__title">
      <div class="total-value-div">
        <span>Selecionar opção</span>
        <div class="total-value__wrapper">
          <el-select
            v-model="selectedType"
            id="select-correlate-type"
            @change="handleSelectedType"
          >
            <el-option
              v-for="(item, index) in types"
              :key="`mult-generic-expenditure-select-type-${item.label}-${index}`"
              :label="item.label"
              :value="item.value"
              :id="'mult-generic-select-type-' + item.value"
            >
            </el-option>
          </el-select>
          <el-row class="total-value__text">
            <span> Valor total da Rubrica: </span>
            {{ getRubricTotal }}
          </el-row>
        </div>
      </div>
      <div class="header-actions__inputs">
        <el-select
          v-if="showFilter"
          class="header-actions__select"
          v-model="selectedFilter"
          filterable
          clearable
          placeholder="Selecione um filtro"
          id="filter-contabil"
          @change="handleChangeFilter"
        >
          <el-option
            v-for="(item, index) in getFilters"
            :key="`accounting-filter-${item}-${index}`"
            :label="item"
            :value="item"
            :id="'filter-contabil' + item"
          />
        </el-select>
        <el-input
          class="search-input"
          v-model="search"
          size="medium"
          placeholder="Pesquisar na tabela"
          clearable
          prefix-icon="el-icon-search"
        ></el-input>
        <el-tooltip :content="tooltipText" placement="top" :open-delay="500">
          <div>
            <el-button
              v-if="isPMO"
              id="correlate-button"
              class="primary-button"
              :disabled="isBtnUnion"
              @click="handleUnion"
            >
              Unir
            </el-button>
          </div>
        </el-tooltip>
      </div>
    </div>
    <el-row class="rubric-container__table">
      <el-table
        height="100%"
        size="small"
        max-height="100%"
        class="rubric-container__table-inner"
        v-loading="loading"
        :data="getPageContent"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column v-if="isPMO" type="selection" width="60" />
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="expand-table">
              <el-table
                size="small"
                class="expand-table__inner"
                :data="props.row.ledgerBookList"
                v-if="props.row.isCorrelate"
              >
                <el-table-column
                  v-for="(element, index) in tableColumnsProps"
                  :key="`sub-table-${element.label}-${index}`"
                  :prop="element.prop"
                  :label="element.label"
                  :min-width="element.minWidth"
                  :formatter="element.formatter"
                  show-overflow-tooltip
                  :align="element.align"
                  :header-align="element.headerAlign"
                  sortable
                />
              </el-table>
              <div v-else>
                <span class="inner-table-warning">
                  Lançamento não foi unificado.
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(element, index) in tableColumnsProps"
          :key="`table-column-${element.label}-${index}`"
          :prop="element.prop"
          :label="element.label"
          :min-width="element.minWidth"
          :formatter="element.formatter"
          show-overflow-tooltip
          :align="element.align"
          :header-align="element.headerAlign"
          sortable
        />
        <el-table-column
          v-for="(column, index) in tableIconColumnsProps"
          :key="`table-icon-column-${column.label}-${index}`"
          :prop="column.prop"
          :label="column.label"
          :min-width="column.minWidth"
          :align="column.align"
          :header-align="column.headerAlign"
          show-overflow-tooltip
          sortable
        >
          <template slot-scope="scope">
            <el-tooltip
              v-if="handleIconControl(scope)"
              placement="top"
              :content="handleIconText(scope)"
              :open-delay="500"
            >
              <i class="el-icon-success icon-success" />
            </el-tooltip>
            <el-tooltip
              v-else
              placement="top"
              :content="handleIconText(scope)"
              :open-delay="500"
            >
              <i class="el-icon-warning icon-warning" />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          min-width="75"
          label="Ações"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="isPMO">
              <el-popover placement="left" trigger="hover" :open-delay="300">
                <el-row class="actions-popover">
                  <el-button
                    v-for="(element, index) in actions"
                    :key="`pop-over-button-${index}`"
                    class="btn-action"
                    @click="handleAction(element.value, scope.row)"
                  >
                    {{ element.label }}
                  </el-button>
                  <el-button
                    class="btn-action"
                    id="transfer-button"
                    @click="handleAction('transfer', scope.row)"
                  >
                    Transferir
                  </el-button>
                  <el-button
                    id="undo-correlate-button"
                    class="btn-action"
                    @click="handleUndoUnion(scope.row)"
                    :disabled="!scope.row.isCorrelate"
                  >
                    Desfazer união
                  </el-button>
                </el-row>
                <el-button
                  slot="reference"
                  icon="el-icon-more"
                  :disabled="isBtnPopover"
                  class="btn-icon"
                />
              </el-popover>
            </div>
            <div v-else>
              <el-popover
                v-if="managerActions.length > 1"
                placement="left"
                trigger="hover"
                :open-delay="300"
              >
                <el-row class="actions-popover">
                  <el-button
                    v-for="(element, index) in managerActions"
                    :key="`manager-action-${element.label}-${index}`"
                    class="btn-action"
                    @click="handleAction(element.value, scope.row)"
                  >
                    {{ element.label }}
                  </el-button>
                </el-row>
                <el-button
                  slot="reference"
                  icon="el-icon-more"
                  class="btn-icon"
                  :disabled="isBtnPopover"
                />
              </el-popover>
              <el-button
                v-else
                type="text"
                @click="handleAction(managerActions[0].value, scope.row)"
                >{{ managerActions[0].label }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div class="paginator-container">
      <el-pagination
        class="page-selector"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="sizes, slot, prev, pager, next"
        :page-size="getPageSize"
        :page-sizes="getPageSizes"
        :current-page.sync="page"
        :total="tableLength"
      >
        <el-row class="current-page">
          {{ getPaginatorText }}
        </el-row>
      </el-pagination>
    </div>
  </el-main>
</template>

<script>
import tableConfig from '@/utils/expenditures'
import { handleFormatLabels } from '@/utils/rubrics'
import { mapGetters } from 'vuex'

export default {
  name: 'MultGeneralExpenditure',
  props: {
    info: {
      type: Object,
      default: () => {
        return { data: {}, totals: {} }
      },
    },
    filters: { type: Object, default: () => {} },
    loading: { type: Boolean, default: () => true },
    showFilter: { type: Boolean, default: () => false },
    filterProp: { type: String, default: () => '' },
  },
  data() {
    return {
      search: '',
      searchColumns: [],
      isBtnUnion: true,
      isBtnPopover: false,
      selectedProjects: [],
      page: 1,
      pageSize: this.$store.state.pageSize,
      pageInterval: this.$store.state.pageSize,
      tableLength: 0,
      tableColumnsProps: [],
      tableIconColumnsProps: [],
      tooltipText:
        'Selecione os checkboxes para habilitar a ação de unir lançamentos.',
      actions: [],
      managerActions: [],
      contentData: [],
      contentBuffer: [],
      selectedType: '',
      selectedFilter: '',
    }
  },
  methods: {
    handleDefineTableProperties() {
      const {
        searchColumns,
        tableColumnsProps,
        tableIconColumnsProps,
        actions,
        managerActions,
      } = tableConfig[this.selectedType]
      this.actions = actions
      this.managerActions = managerActions
      this.searchColumns = searchColumns
      this.tableColumnsProps = tableColumnsProps
      this.tableIconColumnsProps = tableIconColumnsProps
    },
    handleCurrentChange(newPage) {
      this.page = newPage
    },
    handleGetPageContent(data) {
      const pageContent = data.slice(
        this.getPageSize * this.page - this.getPageSize,
        this.getPageSize * this.page
      )
      this.tableLength = data.length
      return pageContent
    },
    handleSelectionChange(val) {
      this.selectedProjects = val.map((element) => {
        return {
          item: element,
        }
      })
    },
    handleChangeFilter() {
      const filter = this.selectedFilter
      if (filter && filter !== '') {
        let newData = this.contentData.filter((item) => {
          let searchValue = String(filter).toLowerCase()
          const hasOnColumn = String(item[this.filterProp])
            .toLowerCase()
            .includes(searchValue)
          if (hasOnColumn) {
            return item
          }
        })
        this.contentBuffer = newData
      } else {
        this.contentBuffer = this.contentData
      }
      this.search = ''
      this.page = 1
    },
    handleAction(action, rowInfo) {
      this.$emit(action, { rowInfo, type: this.selectedType })
    },
    handleUnion() {
      const sendInfo = this.selectedProjects.map(({ item }) => item)
      this.$emit('union', { rowInfo: sendInfo, type: this.selectedType })
    },
    handleUndoUnion({ correlateId }) {
      this.$confirm('Tem certeza que deseja desfazer essa união?', 'Atenção!', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
        confirmButtonClass: 'primary-button',
        cancelButtonClass: 'secondary-button',
        type: '',
      })
        .then(() => {
          this.$emit('undo', {
            rubric: this.selectedType,
            correlateId,
          })
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: 'Operação cancelada.',
          })
        })
    },
    handleBtnControl() {
      const selectLength = this.selectedProjects.length
      let tooltipText =
        'Selecione os checkboxes para habilitar a ação de unir lançamentos.'
      if (selectLength < 2 || selectLength === 0) {
        this.isBtnUnion = true
      } else {
        this.isBtnUnion = false
      }
      if (selectLength > 0) {
        this.isBtnPopover = true
      } else {
        this.isBtnPopover = false
      }
      this.tooltipText = tooltipText
    },
    handleSortChange({ column, order, prop }) {
      const orientation = {
        ascending: false,
        descending: true,
      }

      if (order) {
        let filtred = [...this.contentData]
        filtred.sort((a, b) => {
          if (a[prop] < b[prop]) {
            return -1
          }
          if (a[prop] > b[prop]) {
            return 1
          }
          return 0
        })
        if (orientation[order]) {
          filtred.reverse()
        }
        this.contentBuffer = filtred
      } else {
        this.contentBuffer = this.contentData
      }
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
    handleSelectedType() {
      this.contentBuffer = this.info.data[this.selectedType]
      this.contentData = this.info.data[this.selectedType]
      this.handleDefineTableProperties()
      this.selectedFilter = ''
      this.search = ''
    },
  },
  computed: {
    ...mapGetters(['getPageSize']),
    isPMO() {
      return localStorage.getItem('accessLevel').toLowerCase() === 'pmo'
    },
    getPageSizes() {
      return this.handlePageSizes(this.pageInterval, this.tableLength)
    },
    getPageContent() {
      const pageContent = this.handlePageContent(
        this.search,
        this.searchColumns,
        this.contentBuffer
      )
      return this.handleGetPageContent(pageContent)
    },
    getPaginatorText() {
      const page = this.page
      const pageSize = this.getPageSize
      const dataSize = this.tableLength
      const start = (page - 1) * pageSize + (dataSize > 0 ? 1 : 0)
      const end =
        page * pageSize > dataSize ? dataSize : (page - 1) * pageSize + pageSize

      return `Mostrando de ${start} até ${end} de ${dataSize} itens.`
    },
    getFilters() {
      return this.filters[this.selectedType]
    },
    getRubricTotal() {
      return this.handleFormatValue(
        this.info.totals[this.selectedType].ledgerBook
      )
    },
  },
  watch: {
    info() {
      this.contentBuffer = this.info.data[this.selectedType]
      this.contentData = this.info.data[this.selectedType]
    },
    selectedProjects() {
      this.handleBtnControl()
    },
  },
  created() {
    this.selectedType = Object.keys(this.info.data)[0]
    this.types =
      Object.keys(this.info.data)?.map((element) => {
        return {
          label: handleFormatLabels(element),
          value: element,
        }
      }) || []
    this.contentBuffer = this.info.data[this.selectedType]
    this.contentData = this.info.data[this.selectedType]
    this.handleDefineTableProperties()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
