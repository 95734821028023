export const OTHER_CORR_CORRECT_ORDER = {
  ['OTHER_CORRELS']: [
    'Alugueis e condomínios',
    'Aluguel de Equipamento',
    'Energia elétrica',
    'Água e esgoto',
    'Telefones',
    'Provedores de acesso',
    'Seguro equipamentos',
    'IPTU',
    'CPMF',
    'Outros impostos',
    'Desconto Obtidos outros',
    'Manutenção das instalações - diversos',
    'Limpeza e Conservação',
    'Segurança e Vigilância',
    'Outros Serviços Contratados',
    'Manutenção de Equipamentos de Uso',
    'Material de Limpeza e Conservação',
    'Infraestrutura de Computação',
  ],
}

export const LABEL_PATTERN = {
  provider: 'Fornecedor',
  description: 'Descrição',
  quantity: 'Quantidade',
  invoice: 'N° nota fiscal',
  title: 'Título',
  professional: 'Profissional',
  period: 'Período',
  objective: 'Objetivo',
  passage: 'Tipo',
  subject: 'Assunto',
  MCT: 'MCT',
  equip: 'Equipamentos',
  civil: 'Obras civis',
  books: 'Livros e periódicos',
  training: 'Treinamentos',
  services: 'Serviços de terceiros',
  consumables: 'Materiais de consumo',
  CNPJ: 'CNPJ/CPF',
  occupation: 'Função no Cesar',
  correlates1: 'Correlatos 1',
  correlates2: 'Correlatos 2',
  location: 'Local',
  external: 'PJ',
  direct: 'RH direto',
  indirect: 'RH indireto',
  passage: 'Passagem',
  register: 'Registro',
  role: 'Função do colaborador',
  activity: 'Atividade',
  name: 'Nome',
  justification: 'Justificativa',
  destiny: 'Local',
  trips: 'Viagens',
  classification: 'Tipo de apropriação',
  leasing: 'Equipamentos 2',
  contract: 'N° do contrato',
  acquisitionDate: 'Data aquisição',
  serial: 'Número serial',
  activeNumber: 'Número ativo',
  originalAcquisitionDate: 'Data aquisição original',
  availableValue: 'Valor disponível',
  month: 'Mês',
  activity: 'Atividade',
  hours: 'Carga horária',
  register: 'CNPJ/CPF',
  base: 'Lançamentos não alocados - Base',
  othervinc: 'Lançamentos não alocados - Outros Vínculos',
  others: 'Lançamentos não alocados - Outros',
  benefits: 'Lançamentos não alocados - Benefícios',
  charges: 'Lançamentos não alocados - Encargos',
}

export const PRIORITY_PROP = {
  equip: 'description',
  leasing: 'description',
  civil: 'provider',
  direct: 'name',
  indirect: 'name',
  external: 'name',
  books: 'title',
  consumables: 'description',
  trips: 'professional',
  training: 'provider',
  services: 'provider',
  correlates1: 'accountStruct',
  correlates2: 'accountStruct',
}

export const NOT_ALLOCATED = [
  'base',
  'benefits',
  'charges',
  'others',
  'othervinc',
]
