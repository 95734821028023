import mixins from '@/mixins'
import {
  OTHER_CORR_CORRECT_ORDER,
  LABEL_PATTERN,
  PRIORITY_PROP,
} from '@/constants/rubricsConstants'

const detail = {
  base: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      ledgerBookList: data['ledgerBookList'] || [],
      showInfo: {},
      editable: {},
    }
  },
  othervinc: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      ledgerBookList: data['ledgerBookList'] || [],
      showInfo: {},
      editable: {},
    }
  },
  others: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      ledgerBookList: data['ledgerBookList'] || [],
      showInfo: {},
      editable: {},
    }
  },
  benefits: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      ledgerBookList: data['ledgerBookList'] || [],
      showInfo: {},
      editable: {},
    }
  },
  charges: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      ledgerBookList: data['ledgerBookList'] || [],
      showInfo: {},
      editable: {},
    }
  },
  equip: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        description: {
          value: data['description'] || '',
          label: 'Descrição',
          placeholder: 'Adicione uma descrição',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        quantity: {
          value: data['quantity'] || '',
          label: 'Quantidade',
          placeholder: 'Adicione a quantidade',
          type: 'integer',
          position: 'right',
          min: 0,
        },
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        invoice: {
          value: data['invoice'] || '',
          label: 'N° nota fiscal',
          placeholder: 'Adicione o número da nota fiscal',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        MCT: {
          value: data['MCT'] || '',
          label: 'MCT',
          placeholder: 'Selecione a classificação MCT',
        },
        classification: {
          value: data['classification'] || '',
          label: 'Tipo de apropriação',
          placeholder: 'Selecione o tipo da apropriação',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  leasing: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        description: {
          value: data['description'] || '',
          label: 'Descrição',
          placeholder: 'Adicione uma descrição',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        contract: {
          value: data['contract'] || '',
          type: 'text',
          label: 'N° do contrato',
          placeholder: 'Adicione o número do contrato',
          maxlength: null,
          showLimit: false,
        },
        quantity: {
          value: data['quantity'] || '',
          label: 'Quantidade',
          placeholder: 'Adicione a quantidade',
          type: 'integer',
          position: 'right',
          min: 0,
        },
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        invoice: {
          value: data['invoice'] || '',
          label: 'N° nota fiscal',
          placeholder: 'Adicione o número da nota fiscal',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  civil: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        register: {
          value: data['register'] || '',
          label: 'CPF/CNPJ',
          placeholder: 'Adicione o CPF/CNPJ do colaborador',
          type: 'register',
          maxlength: null,
          showLimit: false,
        },
        description: {
          value: data['description'] || '',
          label: 'Obra realizada',
          placeholder: 'Descreva a obra realizada',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  books: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        title: {
          value: data['title'] || '',
          label: 'Título',
          placeholder: 'Descreva o título',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        quantity: {
          value: data['quantity'] || '',
          label: 'Quantidade',
          placeholder: 'Adicione a quantidade',
          type: 'integer',
          position: 'right',
          min: 0,
        },
      },
    }
  },
  correlates1: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'autoComplete',
          maxlength: null,
          showLimit: false,
        },
        accountStruct: {
          value: data['accountStruct'] || '',
          label: 'Conta contábil',
          placeholder: 'Adicione uma conta contábil',
          type: 'select',
          propName: 'accountStruct',
        },
        invoice: {
          value: data['invoice'] || '',
          label: 'N° nota fiscal',
          placeholder: 'Adicione o número da nota fiscal',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  correlates2: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'autoComplete',
          maxlength: null,
          showLimit: false,
        },
        accountStruct: {
          value: data['accountStruct'] || '',
          label: 'Conta contábil',
          placeholder: 'Adicione uma conta contábil',
          type: 'select',
          propName: 'accountStruct',
        },
        invoice: {
          value: data['invoice'] || '',
          label: 'N° nota fiscal',
          placeholder: 'Adicione o número da nota fiscal',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  consumables: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        description: {
          value: data['description'] || '',
          label: 'Descrição',
          placeholder: 'Adicione uma descrição',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        quantity: {
          value: data['quantity'] || '',
          label: 'Quantidade',
          placeholder: 'Adicione a quantidade',
          type: 'integer',
          position: 'right',
          min: 0,
        },
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        invoice: {
          value: data['invoice'] || '',
          label: 'N° nota fiscal',
          placeholder: 'Adicione o número da nota fiscal',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  training: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        objective: {
          value: data['objective'] || '',
          label: 'Objetivo',
          placeholder: 'Adicione um objetivo',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        subject: {
          value: data['subject'] || '',
          label: 'Assunto',
          placeholder: 'Adicione um assunto',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        period: {
          value: data['period'] || '',
          label: 'Período',
          placeholder: 'Adicione um período',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        hours: {
          value: data['hours'] || '',
          label: 'Carga horária',
          placeholder: 'Adicione uma carga horária',
          type: 'integer',
          position: 'right',
          min: 0,
        },
        activity: {
          value: data['activity'] || '',
          label: 'Atividade',
          type: 'textarea',
          placeholder: 'Descreva a atividade...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  services: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        provider: {
          value: data['provider'] || '',
          label: 'Fornecedor',
          placeholder: 'Adicione um fornecedor',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        register: {
          value: data['register'] || '',
          label: 'CPF/CNPJ',
          placeholder: 'Adicione um CPF/CNPJ',
          type: 'register',
          maxlength: null,
          showLimit: false,
        },
        description: {
          value: data['description'] || '',
          label: 'Descrição dos serviços',
          placeholder: 'Adicione uma descrição',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        invoice: {
          value: data['invoice'] || '',
          label: 'N° nota fiscal',
          placeholder: 'Adicione o número da nota fiscal',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  external: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfo: {
        total: {
          value: formatTableValue(data['value']),
          label: 'Valor total',
        },
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
      },
      editable: {
        name: {
          value: data['name'] || '',
          label: 'Nome',
          placeholder: 'Adicione um colaborador',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        register: {
          value: data['register'] || '',
          label: 'CPF/CNPJ',
          placeholder: 'Adicione o CPF/CNPJ do colaborador',
          type: 'register',
          maxlength: null,
          showLimit: false,
        },
        role: {
          value: data['role'] || '',
          label: 'Função do colaborador',
          placeholder: 'Adicione a função do colaborador',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        workedHours: {
          value: data['workedHours'] || '',
          placeholder: 'Adicione as horas trabalhadas',
          label: 'Horas',
          type: 'text',
        },
        schooling: {
          value: data['schooling'] || '',
          label: 'Escolaridade',
          placeholder: 'Adicione o nível de escolaridade',
          type: 'select',
          propName: 'schooling',
        },
        formation: {
          value: data['formation'] || '',
          label: 'Formação do colaborador',
          placeholder: 'Adicione a formação do colaborador',
          type: 'text',
        },
        apportionType: {
          value: data['apportionType'] || '',
          label: 'Tipo',
          placeholder: 'Adicione o tipo de rh',
          type: 'select',
          propName: 'apportionType',
        },
        activity: {
          value: data['activity'] || '',
          label: 'Atividade',
          type: 'textarea',
          placeholder: 'Atividade do colaborador...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  direct: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfoAdjustment: {
        name: {
          value: data['name'] || '',
          label: 'Nome',
        },
        cpf: {
          value: formatCPF(data['cpf']),
          label: 'CPF',
        },
        role: {
          value: formatInfo(data['role']),

          label: 'Função do colaborador',
        },
      },
      transferInfo: {
        collabValueId: data['collabValueId'] || '',
        register: data['register'] || '',
        sheet: data['sheet']['ledgerBookValue'] || 0,
        prizes: data['prizes']['ledgerBookValue'] || 0,
        charges: data['charges']['ledgerBookValue'] || 0,
        benefits: data['benefits']['ledgerBookValue'] || 0,
      },
      showInfoRH: {
        name: {
          value: formatInfo(data['name']),
          label: 'Nome',
        },
        cpf: {
          value: formatCPF(data['cpf']),
          label: 'CPF',
        },
        role: {
          value: formatInfo(data['role']),
          label: 'Função do colaborador',
        },
        salary: {
          value: formatTableValue(data['sheet']['collabValues']),
          label: 'Salário',
          placeholder: 'Adicione o salário do colaborador',
        },
        prizes: {
          value: formatTableValue(data['prizes']['collabValues']),
          label: 'Premiação',
          placeholder: 'Adicione a premiação do colaborador',
        },
        charges: {
          value: formatTableValue(data['charges']['collabValues']),
          label: 'Encargos',
          placeholder: 'Adicione os encargos do colaborador',
        },
        benefits: {
          value: formatTableValue(data['benefits']['collabValues']),
          label: 'Benefícios',
          placeholder: 'Adicione os benefícios do colaborador',
        },
      },
      showInfo: {
        name: {
          value: data['name'] || '',
          label: 'Nome',
          placeholder: 'Adicione o nome do colaborador',
        },
        cpf: {
          value: formatCPF(data['cpf']),
          label: 'CPF',
          placeholder: 'Adicione o CPF do colaborador',
        },
        role: {
          value: data['role'] || '',
          label: 'Função do colaborador',
          placeholder: 'Adicione a função do colaborador',
        },
        salary: {
          value: formatTableValue(data['sheet']['ledgerBookValue']),
          label: 'Salário',
          placeholder: 'Adicione o salário do colaborador',
        },
        prizes: {
          value: formatTableValue(data['prizes']['ledgerBookValue']),
          label: 'Premiação',
          placeholder: 'Adicione a premiação do colaborador',
        },
        charges: {
          value: formatTableValue(data['charges']['ledgerBookValue']),
          label: 'Encargos',
          placeholder: 'Adicione os encargos do colaborador',
        },
        benefits: {
          value: formatTableValue(data['benefits']['ledgerBookValue']),
          label: 'Benefícios',
          placeholder: 'Adicione os benefícios do colaborador',
        },
      },
      editable: {
        name: {
          value: data['name'] || '',
          label: 'Nome',
          type: 'text',
          placeholder: 'Adicione um colaborador',
        },
        cpf: {
          value: data['cpf'] || '',
          label: 'CPF',
          type: 'cpf',
          placeholder: 'Adicione o CPF do colaborador',
        },
        role: {
          value: data['role'] || '',
          label: 'Função do colaborador',
          placeholder: 'Adicione a função do colaborador',
          type: 'text',
        },
        formation: {
          value: data['formation'] || '',
          label: 'Formação do colaborador',
          placeholder: 'Adicione a formação do colaborador',
          type: 'text',
        },
        workedHours: {
          value: data['workedHours'] || '',
          placeholder: 'Adicione as horas trabalhadas',
          label: 'Horas trabalhadas',
          type: 'text',
        },
        expenseType: {
          value: action === 'union' ? '' : data['expenseType'] || '',
          label: 'Tipo de despesa',
          placeholder: 'Selecione o tipo de despesa',
          type: 'select',
          propName: 'expenseType',
          disabled: action === 'union',
        },
        schooling: {
          value: data['schooling'] || '',
          label: 'Escolaridade',
          placeholder: 'Adicione o nível de escolaridade',
          type: 'select',
          propName: 'schooling',
        },
        activity: {
          value: data['activity'] || '',
          label: 'Atividade',
          type: 'textarea',
          placeholder: 'Atividade do colaborador...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  indirect: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      showInfoAdjustment: {
        name: {
          value: data['name'] || 'não informado',
          label: 'Nome',
        },
        cpf: {
          value: formatCPF(data['cpf']),
          label: 'CPF',
        },
        role: {
          value: formatInfo(data['role']),
          label: 'Função do colaborador',
        },
      },
      transferInfo: {
        collabValueId: data['collabValueId'] || '',
        register: data['register'] || '',
        sheet: data['sheet']['ledgerBookValue'] || 0,
        prizes: data['prizes']['ledgerBookValue'] || 0,
        charges: data['charges']['ledgerBookValue'] || 0,
        benefits: data['benefits']['ledgerBookValue'] || 0,
      },
      showInfoRH: {
        name: {
          value: formatInfo(data['name']),
          label: 'Nome',
        },
        cpf: {
          value: formatCPF(data['cpf']),
          label: 'CPF',
        },
        role: {
          value: formatInfo(data['role']),
          label: 'Função do colaborador',
        },
        salary: {
          value: formatTableValue(data['sheet']['collabValues']),
          label: 'Salário',
          placeholder: 'Adicione o salário do colaborador',
        },
        prizes: {
          value: formatTableValue(data['prizes']['collabValues']),
          label: 'Premiação',
          placeholder: 'Adicione a premiação do colaborador',
        },
        charges: {
          value: formatTableValue(data['charges']['collabValues']),
          label: 'Encargos',
          placeholder: 'Adicione os encargos do colaborador',
        },
        benefits: {
          value: formatTableValue(data['benefits']['collabValues']),
          label: 'Benefícios',
          placeholder: 'Adicione os benefícios do colaborador',
        },
      },
      showInfo: {
        name: {
          value: data['name'] || '',
          label: 'Nome',
        },
        cpf: {
          value: formatCPF(data['cpf']),
          label: 'CPF',
        },
        role: {
          value: data['role'] || '',
          label: 'Função do colaborador',
        },
        role: {
          value: data['role'] || '',
          label: 'Função do colaborador',
        },
        salary: {
          value: formatTableValue(data['sheet']['ledgerBookValue']),
          label: 'Salário',
        },
        prizes: {
          value: formatTableValue(data['prizes']['ledgerBookValue']),
          label: 'Premiação',
        },
        charges: {
          value: formatTableValue(data['charges']['ledgerBookValue']),
          label: 'Encargos',
        },
      },
      editable: {
        name: {
          value: data['name'] || '',
          label: 'Nome',
          type: 'text',
          placeholder: 'Adicione um colaborador',
        },
        cpf: {
          value: data['cpf'] || '',
          label: 'CPF',
          type: 'cpf',
          placeholder: 'Adicione o CPF do colaborador',
        },
        role: {
          value: data['role'] || '',
          label: 'Função do colaborador',
          placeholder: 'Adicione a função do colaborador',
          type: 'text',
        },
        formation: {
          value: data['formation'] || '',
          label: 'Formação do colaborador',
          placeholder: 'Adicione a formação do colaborador',
          type: 'text',
        },
        workedHours: {
          value: data['workedHours'] || '',
          placeholder: 'Adicione as horas trabalhadas',
          label: 'Horas trabalhadas',
          type: 'text',
        },
        expenseType: {
          value: action === 'union' ? '' : data['expenseType'] || '',
          label: 'Tipo de despesa',
          placeholder: 'Selecione o tipo de despesa',
          type: 'select',
          propName: 'expenseType',
          disabled: action === 'union',
        },
        schooling: {
          value: data['schooling'] || '',
          label: 'Escolaridade',
          placeholder: 'Adicione o nível de escolaridade',
          type: 'select',
          propName: 'schooling',
        },
        activity: {
          value: data['activity'] || '',
          label: 'Atividade',
          type: 'textarea',
          placeholder: 'Atividade do colaborador...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
  trips: (data, type, action) => {
    return {
      type: type,
      id: data['id'] || '',
      detailId: data['detailId'] || '',
      ledgerBookList: data['ledgerBookList'] || '',
      isUnion: data['isCorrelate'] || false,
      correlateId: data['correlateId'] || '',
      useCorrelate: true,
      isCorrelate: data['isCorrelate'] || '',
      showInfo: {
        complement: {
          value: data['complement'] || '',
          label: 'Complemento',
        },
        value: {
          value: formatTableValue(data['value']) || '',
          label: 'Valor total',
        },
      },
      ids: {
        collabId: data['collabId'] || '',
      },
      editable: {
        expenseType: {
          value: action === 'union' ? '' : data['expenseType'] || '',
          label: 'Tipo',
          placeholder: 'Selecione o tipo de despesa',
          type: 'select',
          propName: 'expenseType',
          disabled: action === 'union',
        },
        professional: {
          value: data['professional'] || '',
          label: 'Profissional',
          placeholder: 'Adicione um profissional',
          type: 'select',
          propName: 'professional',
        },
        location: {
          value: data['location'] || '',
          label: 'Local',
          placeholder: 'Adicione um local',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        period: {
          value: data['period'] || '',
          label: 'Período',
          placeholder: 'Adicione um período',
          type: 'text',
          maxlength: null,
          showLimit: false,
        },
        objective: {
          value: data['objective'] || '',
          label: 'Objetivo',
          placeholder: 'Adicione um objetivo',
          type: 'textarea',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
        justification: {
          value: data['justification'] || '',
          label: 'Justificativa',
          type: 'textarea',
          placeholder: 'Descreva a justificativa...',
          maxlength: '1000',
          showLimit: true,
          rows: 5,
        },
      },
    }
  },
}

function sortObjectArrayByReferenceArray(arrayToSort, referenceArray, prop) {
  return arrayToSort.sort((a, b) => {
    const indexA = referenceArray.indexOf(a[prop])
    const indexB = referenceArray.indexOf(b[prop])

    if (indexA < indexB) {
      return -1
    }
    if (indexB > indexA) {
      return 1
    }
    return 0
  })
}

function formatTableValue(value) {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

function formatTableDate(date) {
  return mixins.methods.handleFormatDate(date)
}
function formatCPF(cpf) {
  return mixins.methods.handleFormatCPF(cpf)
}

function formatInfo(text) {
  return text?.length > 0 ? text : 'não informado'
}

export function handleFormatLabels(text) {
  return LABEL_PATTERN[text]
}

export function handleGetDetailFormat(data, type, action) {
  return detail[type](data, type, action)
}

export function organizeData(tag, data = []) {
  const prop = PRIORITY_PROP[tag]
  const correlates = ['correlates1', 'correlates2']

  return correlates.includes(tag)
    ? sortObjectArrayByReferenceArray(
        data,
        OTHER_CORR_CORRECT_ORDER['OTHER_CORRELS'],
        prop
      )
    : data.sort((a, b) =>
        String(a[prop]).localeCompare(String(b[prop]), [], {
          numeric: true,
        })
      )
}
