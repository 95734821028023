<template>
  <el-main class="rh-container">
    <div class="rh-container__header">
      <el-col class="rh-container__header--wrapper">
        <el-row class="rh-container__type">
          <span>Selecionar opção</span>
          <el-select
            v-model="selectedType"
            id="select-correlate-type"
            @change="handleSelectedType"
          >
            <el-option-group v-for="(options, key) in optionGroups" :key="key">
              <el-option
                v-for="(item, index) in options"
                :key="`correlate-select-type-${item.label}-${index}`"
                :label="item.label"
                :value="item.value"
                :id="'select-correlate-type-' + item.value"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-row>
        <div v-if="this.selectedType === 'notAllocated'">
          <el-row class="rh-container__info">
            <span>
              Valor total dos lançamentos:
              <b>{{ handleFormatValue(headerInfo.ledgerBook) }}</b>
            </span>
          </el-row>
        </div>
        <div v-else-if="this.selectedType !== 'external'">
          <el-row class="rh-container__info">
            <span>
              Total no Sistema: {{ handleFormatValue(headerInfo.SQL) }}
            </span>
            <span>
              Total no Pirâmide: {{ handleFormatValue(headerInfo.pyramid) }}
            </span>
            <span class="bold">
              Diferença: {{ handleFormatValue(headerInfo.difference) }}
            </span>
          </el-row>
        </div>
        <div v-else>
          <el-row class="rh-container__info">
            <span>
              Valor total da Rubrica:
              <b>{{ handleFormatValue(headerInfo.ledgerBook) }}</b>
            </span>
          </el-row>
        </div>
      </el-col>
      <el-col class="rh-container__search">
        <el-input
          class="search-input"
          v-model="search"
          size="medium"
          placeholder="Pesquisar na tabela"
          clearable
          prefix-icon="el-icon-search"
        ></el-input>
        <el-tooltip :content="tooltipText" placement="top" :open-delay="500">
          <div>
            <el-button
              v-if="isPMO && this.selectedType !== 'notAllocated'"
              id="union-button-rh"
              class="primary-button"
              :disabled="isBtnUnion"
              @click="handleUnion"
            >
              Unir
            </el-button>
          </div>
        </el-tooltip>
      </el-col>
    </div>
    <div class="table-container">
      <el-row
        class="table-container__inner"
        v-if="this.selectedType === 'notAllocated'"
      >
        <el-table
          size="small"
          height="100%"
          max-height="100%"
          class="table-container__body--inner"
          v-loading="loading"
          :data="getPageContent"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column
            v-for="(element, index) in tableColumnsProps"
            :key="`table-column-${element.label}-${index}`"
            :prop="element.prop"
            :label="element.label"
            :min-width="element.minWidth"
            :formatter="element.formatter"
            show-overflow-tooltip
            :align="element.align"
            :header-align="element.headerAlign"
            sortable
          />
          <el-table-column
            min-width="80"
            label="Ações"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-popover placement="left" trigger="hover" :open-delay="300">
                <el-row class="actions-popover">
                  <el-button
                    v-for="(element, index) in actions"
                    :key="`rh-action-poop-over-${element.label}-${index}`"
                    class="btn-action"
                    @click="handleAction(element.value, scope.row)"
                  >
                    {{ element.label }}
                  </el-button>
                </el-row>
                <el-button
                  slot="reference"
                  icon="el-icon-more"
                  class="btn-icon"
                  :disabled="isBtnPopover"
                />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row
        v-if="
          this.selectedType !== 'notAllocated' &&
          this.selectedType !== 'external'
        "
        class="table-container__inner"
      >
        <el-table
          size="small"
          height="100%"
          max-height="100%"
          class="table-container__body--inner"
          v-loading="loading"
          :data="getPageContent"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column v-if="isPMO" type="selection" width="55" />
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="expand-table">
                <el-table
                  size="small"
                  class="expand-table__inner"
                  :data="props.row.ledgerBookList"
                  v-if="props.row.isCorrelate"
                >
                  <el-table-column
                    prop="complement"
                    label="Complemento"
                    show-overflow-tooltip
                    sortable
                  />
                </el-table>
                <div v-else>
                  <span class="inner-table-warning">
                    Lançamento não foi unificado.
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(element, index) in tableColumnsProps"
            :key="`table-column-${element.label}-${index}`"
            :prop="element.prop"
            :label="element.label"
            :min-width="element.minWidth"
            :formatter="element.formatter"
            show-overflow-tooltip
            :align="element.align"
            :header-align="element.headerAlign"
            sortable
          />
          <el-table-column
            :min-width="180"
            header-align="center"
            align="center"
            label="Percentual de rateio"
            prop="distributionApportion"
            sortable
          >
            <template slot-scope="scope">
              <div class="row">
                {{ handleFormatApportion(scope) }}
                <div v-if="handleApportionShow(scope)">
                  <el-tooltip :open-delay="500">
                    <div slot="content" class="tooltip-apportion">
                      <span v-if="handleIsdetail(scope)">
                        Colaborador não possui um percentual de rateio. Ajuste
                        esta informação no menu “
                        <strong> ações </strong>
                        ” na opção “
                        <strong> ajustar rateio </strong>
                        ”.
                      </span>
                      <span v-else>
                        Antes de ajustar o rateio, detalhe o colaborador
                      </span>
                    </div>
                    <i class="el-icon-warning icon-alert" />
                  </el-tooltip>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(element, index) in Object.keys(columnsHeader)"
            :label="columnsHeader[element]"
            :prop="element"
            align="center"
            sortable
            :sort-method="handleCompare(element)"
            :key="'columnValues#' + index"
          >
            <el-table-column
              :prop="element"
              :min-width="180"
              show-overflow-tooltip
              align="center"
            >
              <template slot="header">
                <div>
                  <div class="subHeader-container">
                    <span>
                      Sistema:
                      {{ handleSubHeaderTotal(element, 'SQL') }}
                    </span>
                    <span>
                      Pirâmide:
                      {{ handleSubHeaderTotal(element, 'pyramid') }}
                    </span>
                    <span>
                      Diferença:
                      {{ handleSubHeaderTotal(element, 'difference') }}
                    </span>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="row">
                  {{ formatColumnValue(scope.row[element]) }}
                  <div v-if="handleHasDiff(scope.row[element])">
                    <el-tooltip :open-delay="500">
                      <div slot="content" class="tooltip-icon">
                        <span>
                          Sistema:
                          {{
                            handleTableTooltip(
                              scope.row[element],
                              'collabValues'
                            )
                          }}
                        </span>
                        <span>
                          Razão:
                          {{
                            handleTableTooltip(
                              scope.row[element],
                              'ledgerBookValue'
                            )
                          }}
                        </span>
                        <span>
                          Diferença:
                          {{
                            handleTableTooltip(scope.row[element], 'difference')
                          }}
                        </span>
                      </div>
                      <i class="el-icon-warning icon-alert" />
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="total"
            label="Total"
            :min-width="150"
            align="center"
            sortable
            :sort-method="handleCompare('total')"
          >
            <el-table-column
              prop="total"
              :min-width="150"
              show-overflow-tooltip
              align="center"
              :formatter="formatTotal"
            >
              <template slot="header">
                <div>
                  <span> Total </span>
                  <div class="row">
                    {{ handleHeaderTotal() }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            v-for="(column, index) in tableIconColumnsProps"
            :key="`table-icon-column-rh-${column.label}-${index}`"
            :prop="column.prop"
            :label="column.label"
            :min-width="column.minWidth"
            :align="column.align"
            :header-align="column.headerAlign"
            show-overflow-tooltip
            sortable
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="handleIconControl(scope)"
                placement="top"
                :content="handleIconText(scope)"
                :open-delay="500"
              >
                <i class="el-icon-success icon-success" />
              </el-tooltip>
              <el-tooltip
                v-else
                placement="top"
                :content="handleIconText(scope)"
                :open-delay="500"
              >
                <i class="el-icon-warning icon-warning" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            min-width="80"
            label="Ações"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-popover placement="left" trigger="hover" :open-delay="300">
                <el-row class="actions-popover">
                  <el-button
                    v-for="(element, index) in actions"
                    :key="`rh-action-poop-over-${element.label}-${index}`"
                    class="btn-action"
                    @click="handleAction(element.value, scope.row)"
                  >
                    {{ element.label }}
                  </el-button>
                  <el-button
                    class="btn-action"
                    id="transfer-button"
                    @click="handleAction('transfer', scope.row)"
                  >
                    Transferir
                  </el-button>
                  <el-button
                    id="detail-button"
                    class="btn-action"
                    @click="handleAction('detail', scope.row)"
                    :disabled="
                      !scope.row.correlateId ||
                      scope.row.ledgerBookList.length === 0
                    "
                  >
                    Detalhar
                  </el-button>
                  <el-button
                    id="entry-button"
                    class="btn-action"
                    @click="handleAction('adjustment', scope.row)"
                    :disabled="!scope.row.collabValueId"
                  >
                    Ajustar valor
                  </el-button>
                  <el-button
                    id="rateio-button"
                    class="btn-action"
                    @click="handleAction('rateio', scope.row)"
                    :disabled="!scope.row.collabValueId"
                  >
                    Ajustar rateio
                  </el-button>
                  <el-button
                    id="undo-correlate-button"
                    class="btn-action"
                    @click="handleUndoUnion(scope.row)"
                    :disabled="!scope.row.isCorrelate"
                  >
                    Desfazer união
                  </el-button>
                  <el-button
                    id="undo-correlate-button"
                    class="btn-action"
                    :disabled="scope.row.collabValueId ? true : false"
                    @click="handleNewCollaborator(scope.row)"
                  >
                    Adicionar novo colaborador
                  </el-button>
                </el-row>
                <el-button
                  slot="reference"
                  icon="el-icon-more"
                  class="btn-icon"
                  :disabled="isBtnPopover"
                />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row
        v-if="this.selectedType === 'external'"
        class="table-container__inner"
      >
        <el-table
          size="small"
          height="100%"
          :border="false"
          v-loading="loading"
          max-height="100%"
          class="rubric-container__table-inner"
          :data="getPageContent"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="expand-table">
                <el-table
                  size="small"
                  class="expand-table__inner"
                  :data="props.row.ledgerBookList"
                  v-if="props.row.isCorrelate"
                >
                  <el-table-column
                    v-for="(element, index) in tableColumnsProps"
                    :key="`inner-table-prop-pj-${index}`"
                    :prop="element.prop"
                    :label="element.label"
                    :min-width="element.minWidth"
                    :formatter="element.formatter"
                    show-overflow-tooltip
                    :align="element.align"
                    :header-align="element.headerAlign"
                    sortable
                  />
                </el-table>
                <div v-else>
                  <span class="inner-table-warning">
                    Lançamento não foi unificado.
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(element, index) in tableColumnsProps"
            :key="`column-external-${index}`"
            :prop="element.prop"
            :label="element.label"
            :min-width="element.minWidth"
            :formatter="element.formatter"
            show-overflow-tooltip
            :align="element.align"
            :header-align="element.headerAlign"
            sortable
          />
          <el-table-column
            v-for="(column, index) in tableIconColumnsProps"
            :key="`table-icon-column-external-${column.label}-${index}`"
            :prop="column.prop"
            :label="column.label"
            :min-width="column.minWidth"
            :align="column.align"
            :header-align="column.headerAlign"
            show-overflow-tooltip
            sortable
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="handleIconControl(scope)"
                placement="top"
                :content="handleIconText(scope)"
                :open-delay="500"
              >
                <i class="el-icon-success icon-success" />
              </el-tooltip>
              <el-tooltip
                v-else
                placement="top"
                :content="handleIconText(scope)"
                :open-delay="500"
              >
                <i class="el-icon-warning icon-warning" />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="
              (isPMO && actions.length > 1) ||
              (!isPMO && managerActions.length > 1)
                ? 80
                : 120
            "
            label="Ações"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <div v-if="isPMO">
                <el-popover
                  v-if="actions.length > 1"
                  placement="left"
                  trigger="hover"
                  :open-delay="300"
                >
                  <el-row class="actions-popover">
                    <el-button
                      v-for="(element, index) in actions"
                      :key="`action-external-pop-over-${element.label}-${index}`"
                      class="btn-action"
                      @click="handleAction(element.value, scope.row)"
                    >
                      {{ element.label }}
                    </el-button>
                    <el-button
                      class="btn-action"
                      id="transfer-button"
                      @click="handleAction('transfer', scope.row)"
                    >
                      Transferir
                    </el-button>
                    <el-button
                      id="undo-correlate-button"
                      class="btn-action"
                      @click="handleUndoUnion(scope.row)"
                      :disabled="!scope.row.isCorrelate"
                    >
                      Desfazer união
                    </el-button>
                  </el-row>
                  <el-button
                    slot="reference"
                    icon="el-icon-more"
                    class="btn-icon"
                    :disabled="isBtnPopover"
                  />
                </el-popover>
                <el-button
                  v-else
                  type="text"
                  @click="handleAction(actions[0].value, scope.row)"
                  >{{ actions[0].label }}</el-button
                >
              </div>
              <div v-else>
                <el-popover
                  v-if="managerActions.length > 1"
                  placement="left"
                  trigger="hover"
                  :open-delay="300"
                >
                  <el-row class="actions-popover">
                    <el-button
                      v-for="(element, index) in managerActions"
                      :key="`external-manager-action-${element.label}-${index}`"
                      class="btn-action"
                      @click="handleAction(element.value, scope.row)"
                    >
                      {{ element.label }}
                    </el-button>
                  </el-row>
                  <el-button
                    slot="reference"
                    icon="el-icon-more"
                    class="btn-icon"
                    :disabled="isBtnPopover"
                  />
                </el-popover>
                <el-button
                  v-else
                  type="text"
                  @click="handleAction(managerActions[0].value, scope.row)"
                  :disabled="isBtnPopover"
                >
                  {{ managerActions[0].label }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <div class="paginator-container">
        <el-pagination
          class="page-selector"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-sizes="getPageSizes"
          :page-size="pageSize"
          :total="tableLength"
          layout="sizes, slot, prev, pager, next"
        >
          <el-row class="current-page">
            {{ getPaginatorText }}
          </el-row>
        </el-pagination>
      </div>
    </div>
  </el-main>
</template>

<script>
import tableConfig from '@/utils/expenditures'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'RH',
  props: {
    costCenterInformations: Object,
    propInfo: Object,
    propTotals: {
      type: Object,
      default: () => {
        return {
          direct: {},
          indirect: {},
          external: {},
          notAllocated: {},
        }
      },
    },
    loading: { type: Boolean, default: () => true },
  },
  data() {
    return {
      search: '',
      page: 1,
      pageSize: this.$store.state.pageSize,
      pageInterval: this.$store.state.pageSize,
      isBtnUnion: true,
      isBtnPopover: false,
      tableLength: 0,
      contentData: [],
      contentBuffer: [],
      tableColumnsProps: [],
      tableIconColumnsProps: [],
      tooltipText:
        'Selecione os checkboxes para habilitar a ação de unir lançamentos.',
      searchColumns: [],
      headerInfo: {},
      selectedType: 'direct',
      selectedProjects: [],
      columnsHeader: {
        sheet: 'Salário',
        prizes: 'Premiação',
        charges: 'Encargos',
        benefits: 'Benefícios',
      },
      actions: [
        {
          value: 'detail',
          label: 'Relatar atividade',
        },
      ],
      managerActions: [
        {
          value: 'detail',
          label: 'Relatar atividade',
        },
      ],
      optionGroups: {
        rh: [
          { value: 'direct', label: 'RH direto' },
          { value: 'indirect', label: 'RH indireto' },
          { value: 'external', label: 'Serviço PJ' },
        ],
        notAllocatedOption: [
          { value: 'notAllocated', label: 'Lançamentos não alocados' },
        ],
      },
    }
  },
  methods: {
    ...mapActions(['doCollaborator']),

    handleHasDiff(info) {
      return info?.difference || 0
    },
    handleCurrentChange(newPage) {
      this.page = newPage
    },
    handleDefineTableProperties() {
      const {
        tableColumnsProps,
        actions,
        searchColumns,
        tableIconColumnsProps,
      } = tableConfig[this.selectedType]
      this.actions = actions
      this.searchColumns = searchColumns
      this.tableColumnsProps = tableColumnsProps
      this.tableIconColumnsProps = tableIconColumnsProps
    },
    handleCompare(type) {
      return function compare(a, b) {
        if (a[type].collabValues < b[type].collabValues) {
          return -1
        }
        if (a[type].collabValues > b[type].collabValues) {
          return 1
        }
        return 0
      }
    },
    handleFormatHeader() {
      const totals = this.propTotals[this.selectedType]
      this.headerInfo = {
        pyramid: totals?.pyramid || 0,
        difference: totals?.difference || 0,
        SQL: totals?.SQL || 0,
        ledgerBook: totals?.ledgerBook || 0,
      }
    },
    formatColumnValue({ collabValues }) {
      return this.handleFormatValue(collabValues)
    },
    formatTotal(row, column, cellValue, index) {
      return this.handleFormatValue(cellValue?.collabValues || 0)
    },
    formatTableValue(row, column, cellValue, index) {
      return cellValue !== undefined && cellValue?.length > 0 ? cellValue : '-'
    },
    handleNewCollaborator({
      correlateId,
      isCorrelate,
      benefits,
      charges,
      prizes,
      sheet,
    }) {
      this.doCollaborator({
        [isCorrelate ? 'correlateId' : 'ledgerBookId']: correlateId,
        rubric: this.selectedType,
        collabValues: {
          sheet: sheet.ledgerBookValue,
          prizes: prizes.ledgerBookValue,
          charges: charges.ledgerBookValue,
          benefits: benefits.ledgerBookValue,
        },
        costCenterInformations: this.costCenterInformations,
      })
      this.$router.push({
        name: 'NewCollaboratorInRelated',
      })
    },
    handleUndoUnion({ correlateId }) {
      this.$confirm('Tem certeza que deseja desfazer essa união?', 'Atenção!', {
        confirmButtonText: 'Desfazer',
        cancelButtonText: 'Cancelar',
        confirmButtonClass: 'primary-button',
        cancelButtonClass: 'secondary-button',
        type: '',
      })
        .then(() => {
          this.$emit('undo', {
            rubric: this.selectedType,
            correlateId,
          })
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: 'Operação cancelada.',
          })
        })
    },
    handleUnion() {
      const sendInfo = this.selectedProjects.map(({ item }) => item)
      const collabValueIdList = new Set(
        sendInfo?.reduce((prev, curr) => {
          if (curr?.collabValueId) {
            prev.push(curr.collabValueId)
          }
          return prev
        }, []) || []
      )

      if (collabValueIdList.size > 1) {
        this.$confirm(
          'Ao unir o lançamento de um colaborador com o lançamento de um outro colaborador diferente, o sistema pode mostrar novamente informações de folha de um dos colaboradores na lista. Deseja continuar com esta ação?',
          'União de lançamentos de colaboradores diferentes',
          {
            confirmButtonText: 'Continuar com a ação',
            cancelButtonText: 'Cancelar',
            type: '',
            confirmButtonClass: 'primary-button',
            cancelButtonClass: 'secondary-button',
          }
        )
          .then(() => {
            this.$emit('union', { rowInfo: sendInfo, type: this.selectedType })
          })
          .catch(() => {})
      } else {
        this.$emit('union', { rowInfo: sendInfo, type: this.selectedType })
      }
    },

    handleAction(action, rowInfo) {
      this.$emit(action, {
        rowInfo,
        type:
          this.selectedType === 'notAllocated'
            ? rowInfo?.rubricType || ''
            : this.selectedType,
      })
    },
    handleSelectionChange(val) {
      this.selectedProjects = val.map((element) => {
        return {
          item: element,
        }
      })
    },
    handleSortChange({ column, order, prop }) {
      const orientation = {
        ascending: false,
        descending: true,
      }

      if (order) {
        let filtred = [...this.contentData]
        filtred.sort((a, b) => {
          if (a[prop] < b[prop]) {
            return -1
          }
          if (a[prop] > b[prop]) {
            return 1
          }
          return 0
        })
        if (orientation[order]) {
          filtred.reverse()
        }
        this.contentBuffer = filtred
      } else {
        this.contentBuffer = this.contentData
      }
    },
    handleBtnControl() {
      const selectLength = this.selectedProjects.length
      let tooltipText =
        'Selecione os checkboxes para habilitar a ação de unir lançamentos.'
      if (selectLength < 2 || selectLength === 0) {
        this.isBtnUnion = true
      } else {
        this.isBtnUnion = false
      }
      if (selectLength > 0) {
        this.isBtnPopover = true
      } else {
        this.isBtnPopover = false
      }

      this.tooltipText = tooltipText
    },
    handleTableTooltip(value, type) {
      return this.handleFormatValue(value[type])
    },
    handleHeaderTotal() {
      let value = this.headerInfo?.SQL || 0
      return this.handleFormatValue(value)
    },
    handleSubHeaderTotal(text, prop) {
      const totals = this.propTotals[this.selectedType][text]
      const value = totals ? totals[prop] : 0
      return this.handleFormatValue(value)
    },
    handleGetPageContent(data) {
      const pageContent = data.slice(
        this.getPageSize * this.page - this.getPageSize,
        this.getPageSize * this.page
      )
      this.tableLength = data.length
      return pageContent
    },
    handleSelectedType(setVariables = true) {
      if (setVariables) {
        this.page = 1
      }
      this.contentData = this.propInfo[this.selectedType]
      this.contentBuffer = this.propInfo[this.selectedType]
      this.handleDefineTableProperties()
      this.handleFormatHeader()
    },
    handleReduceRateio(rateio) {
      return rateio?.reduce((prev, curr) => {
        return prev + curr?.distributionApportion || 0
      }, 0)
    },
    handleFormatApportion(scope) {
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }

      const apportion = this.handleReduceRateio(scope.row?.distributions)

      return apportion > 0
        ? `${apportion.toLocaleString('pt-BR', options)}%`
        : '-'
    },
    handleApportionShow(scope) {
      const apportion = this.handleReduceRateio(scope.row?.distributions)
      return apportion === 0
    },
    handleIsdetail(scope) {
      return scope.row?.collabValueId
    },
  },
  computed: {
    ...mapGetters(['getPageSize']),
    isPMO() {
      return localStorage.getItem('accessLevel').toLowerCase() === 'pmo'
    },
    getPageSizes() {
      return this.handlePageSizes(this.pageInterval, this.tableLength)
    },
    getPageContent() {
      const pageContent = this.handlePageContent(
        this.search,
        this.searchColumns,
        this.contentBuffer
      )
      return this.handleGetPageContent(pageContent)
    },
    getPaginatorText() {
      const page = this.page
      const pageSize = this.getPageSize
      const dataSize = this.tableLength
      const start = (page - 1) * pageSize + (dataSize > 0 ? 1 : 0)
      const end =
        page * pageSize > dataSize ? dataSize : (page - 1) * pageSize + pageSize

      return `Mostrando de ${start} até ${end} de ${dataSize} itens.`
    },
  },
  watch: {
    propInfo() {
      this.handleSelectedType(false)
    },
    selectedProjects() {
      this.handleBtnControl()
    },
  },
  async created() {
    this.handleSelectedType()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
