import api from '../instance'

export function getProjectDetails(financialReportId) {
  return api.get('/monthlyProjectDetails', {
    params: { financialReportId },
  })
}

export function updateInitialValue(financialReportId, initialValue) {
  return api.put(`/updateInitialValue/${financialReportId}`, { initialValue })
}
