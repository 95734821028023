<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      :drawerName="getDrawerTitle"
      :btnLabel="getBtnLabel"
      :isBtnDisabled="getBtnActionStatus"
      @close="handleClose"
      @save="handleSaveRubric"
    >
      <div class="drawer-detail">
        <div v-if="isTransfer" class="transfer-container">
          <span class="bold"> Transferir lançamento para outra rubrica </span>
          <div class="transfer-info">
            Rubrica atual do lançamento
            <span>
              {{ getCurrentRubricName }}
            </span>
          </div>

          <el-select
            v-model="selectedRubricTarget"
            id="locale-filter"
            clearable
            filterable
          >
            <el-option
              v-for="(element, index) in rubricOptions"
              :key="'TargetRubric' + index"
              :label="element.label"
              :value="element.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-else-if="isUnion" class="union-container">
          <span> Informações dos lançamentos </span>
          <div
            v-for="(row, index) in info.rowInfo"
            :key="`union-detail-${index}`"
            class="union-container__inner"
          >
            <span>{{ `Lançamento ${++index}:` }}</span>
            {{ handleUnionShowInfo(row) }}
          </div>
          <div>
            Valor total dos lançamentos:<span> {{ getUnionSum }}</span>
          </div>
        </div>
        <div v-if="showDetail">
          <div v-if="showDetailHeader">
            <span> Informações do lançamento: </span>
            <div div class="drawer-detail__header">
              <el-row
                v-for="(info, index) in handleShowInfo"
                :key="'drawerInfo' + index"
              >
                <span>{{ info.label }}</span>
                {{ info.value }}
              </el-row>
            </div>
            <span> Informações editaveis: </span>
          </div>
          <div class="drawer-detail__edit">
            <el-row v-for="(item, index) in inputLabels" :key="index">
              <div v-if="handleIsMCT(item)" class="flex-col">
                {{ detailData.editable[item].label }}
                <el-select
                  v-model="detailData.editable.MCT.value"
                  id="locale-filter"
                  :placeholder="detailData.editable.MCT.placeholder"
                  filterable
                >
                  <el-option
                    v-for="element in MCTOptions"
                    :key="'MCT' + element"
                    :label="element"
                    :value="element"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else-if="item === classificationKey">
                <div>
                  {{
                    detailData.editable.MCT.value === 'Equipamento outros'
                      ? 'Relatar outro'
                      : 'Tipo de apropriação'
                  }}
                </div>
                <div
                  v-if="detailData.editable.MCT.value === 'Equipamento outros'"
                  class="column-container"
                >
                  <el-input
                    v-model="detailData.editable.classification.value"
                    :rows="5"
                    type="textarea"
                    placeholder="Outro equipamento"
                  />
                </div>
                <div v-else>
                  <el-select
                    class="drawer-detail__drop"
                    v-model="detailData.editable.classification.value"
                    id="locale-filter"
                    filterable
                    :placeholder="detailData.editable[item].placeholder"
                  >
                    <el-option
                      v-for="element in MCTTypesOptions[
                        detailData.editable.MCT.value
                      ]"
                      :key="'MCTTypes' + element"
                      :label="element"
                      :value="element"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div v-else>
                <GenericInput
                  v-if="handleGetVisibility(item)"
                  class="input-class"
                  :onClear="handleChangeData"
                  :inputData="detailData.editable[item]"
                  :inputOptions="inputOptions[item]"
                  :inputState="detailData.editable[item].disabled"
                  @select="handleInputSelect"
                />
              </div>
            </el-row>
          </div>
        </div>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'
import GenericInput from '@/components/GenericInput/GenericInput.vue'
import { handleGetDetailFormat, handleFormatLabels } from '@/utils/rubrics'

export default {
  name: 'DrawerActions',
  components: {
    Drawer,
    GenericInput,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: () => 'transfer',
    },
    rubricsDetails: {
      type: Object,
      default: () => {},
    },
    professionalOptions: {
      type: Array,
      default: () => [],
    },
    providerOptions: {
      type: Array,
      default: () => [],
    },
    schoolingOptions: {
      type: Array,
      default: () => [],
    },
    accountStructOptions: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
  },
  data() {
    return {
      type: '',
      inputLabels: [],
      detailData: {
        editable: {},
      },
      rubricOptions: [],
      MCTKey: 'MCT',
      classificationKey: 'classification',
      selectedRubricTarget: '',
      isTransfer: true,
      isUnion: false,
      isAdjustment: false,
      showDetail: false,
      labels: {
        transfer: {
          title: 'Transferir lançamento',
        },
        detail: {
          title: 'Detalhamento do lançamento',
        },
        union: {
          title: 'União de lançamentos',
        },
        adjustment: {
          title: 'Ajuste de valor do relatório',
        },
      },
      MCTOptions: ['Software', 'Equipamento outros', 'Bens de informática'],
      MCTTypesOptions: {
        Software: [
          'Licença anual',
          'Licença mensal',
          'Aluguel',
          'Aquisição',
          'Cessão de recursos materiais',
        ],
        'Bens de informática': [
          'Aluguel',
          'Aquisição',
          'Cessão de recursos materiais',
          'Outro',
        ],
        'Equipamento outros': ['Outros'],
      },
      inputOptions: {
        apportionType: [
          {
            value: 'direct',
            label: 'Direto',
          },
          {
            value: 'indirect',
            label: 'Indireto',
          },
        ],
        expenseType: [
          { value: 'passage', label: 'Passagens' },
          { value: 'hosting', label: 'Hospedagens' },
          { value: 'vehiclesLoc', label: 'Locação de veículos' },
          { value: 'discount', label: 'Descontos Obtidos viagens' },
          { value: 'others', label: 'Outros gastos' },
        ],
        accountStruct: [],
        professional: [],
        schooling: [],
        provider: [],
      },
      ids: {},
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleInputSelect(data) {
      const isTrip = [this.info.type, this.selectedRubricTarget].includes(
        'trips'
      )
      if (isTrip && data.propName === 'professional') {
        this.detailData.editable[data.propName].value = data.label
        this.detailData.ids['collabId'] = data.id
      }
      this.ids = { ...this.detailData.ids }
    },
    handleGetVisibility(input) {
      if (input === 'expenseType' && this.info.rowInfo.isCorrelate) {
        return false
      } else {
        return true
      }
    },
    handleSaveRubric() {
      let { type, editable, ids, correlateId, isUnion } = this.detailData
      let sendType = type
      let send = {}
      let sendEditable = JSON.parse(JSON.stringify(editable))

      for (const key in sendEditable) {
        const { type } = sendEditable[key]
        sendEditable[key] =
          type === 'register'
            ? sendEditable[key].value?.replace(/\D/g, '')
            : sendEditable[key].value
      }

      if (this.action === 'union') {
        let newValue = 0

        this.info.rowInfo.forEach(({ value }) => {
          newValue += value
        })

        const ledgerBookIdList =
          this.info.rowInfo
            ?.map(({ ledgerBookList }) => {
              return (
                ledgerBookList?.map(({ id }) => {
                  return id
                }) || []
              )
            })
            .flat(1) || []

        if (type === 'direct' || type === 'indirect') {
          let moreInfo = {
            name: this.info.rowInfo[0]?.name || '',
            cpf: this.info.rowInfo[0]?.cpf || '',
            role: this.info.rowInfo[0]?.role || '',
            sheet: 0,
            prizes: 0,
            charges: 0,
            benefits: 0,
            collabValueId: this.info.rowInfo[0]?.collabValueId || '',
          }

          this.info.rowInfo.forEach((element) => {
            moreInfo.sheet += element.sheet.ledgerBookValue
            moreInfo.prizes += element.prizes.ledgerBookValue
            moreInfo.charges += element.charges.ledgerBookValue
            moreInfo.benefits += element.benefits.ledgerBookValue
          })
          send = {
            newCorrelate: {
              ...moreInfo,
              ...sendEditable,
              value: newValue,
              rubricId: this.rubricsDetails[type].rubricId,
              type,
            },
            ledgerBookIdList,
          }
        } else {
          send = {
            newCorrelate: {
              ...ids,
              ...sendEditable,
              value: newValue,
              rubricId: this.rubricsDetails[type].rubricId,
              type,
            },
            ledgerBookIdList,
          }
        }

        this.$emit(this.action, { ...send, rubric: type })
      } else if (this.action === 'transfer') {
        const rubricId = this.rubricsDetails[sendType].rubricId
        const idList = this.info.rowInfo.ledgerBookList.map(({ id }) => {
          return id
        })

        const details = { ...sendEditable }

        const sendData = {
          details,
          sendType,
          rubricId,
          idList,
          correlateId: isUnion ? correlateId : '',
          type: this.info.type,
        }
        this.$emit(this.action, sendData)
      } else {
        const isCorrelate = this.info.rowInfo.isCorrelate
        let correlateId = this.info.rowInfo.correlateId || ''
        let ledgerBookIdList = []
        let collabValueId = ''

        if (isCorrelate) {
          ledgerBookIdList = this.info.rowInfo.ledgerBookList.map(
            ({ id }) => id
          )
        } else {
          ledgerBookIdList.push(correlateId)
          correlateId = null
        }
        if (this.info.type === 'direct' || this.info.type === 'indirect') {
          ledgerBookIdList = this.info.rowInfo.ledgerBookList.map(
            ({ id }) => id
          )
          collabValueId = this.info.rowInfo.collabValueId
        }
        send = {
          ledgerBookIdList,
          type,
          newCorrelate: {
            ...this.ids,
            ...sendEditable,
            collabValueId,
            rubricId: this.rubricsDetails[type].rubricId,
          },
          correlateId,
        }

        if (type === 'trips') {
          send.newCorrelate[send.newCorrelate.expenseType] =
            this.info.rowInfo?.value || 0
        }
        this.$emit(this.action, { ...send, rubric: type })
      }
    },
    handleFormatInfo() {
      let targetType = this.info.type
      let data = ''
      if (this.action === 'union') {
        data = this.info.rowInfo.reduce((prev, current) => {
          Object.keys(current).forEach(
            (key) => (prev[key] = prev[key] || current[key])
          )
          return prev
        }, {})
      } else {
        data = this.info.rowInfo
      }

      targetType =
        this.selectedRubricTarget.length > 0
          ? this.selectedRubricTarget
          : this.info.type

      const formatDetail = handleGetDetailFormat(data, targetType, this.action)

      this.detailData = formatDetail
      this.inputLabels = Object.keys(formatDetail.editable)
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
    handleShowDetail() {
      this.showDetail = this.selectedRubricTarget.length > 0
      this.showDetailHeader = this.showDetail
    },
    handleIsMCT(item) {
      return item === this.MCTKey
    },
    handleRubricOptions() {
      const rubric = this.rubricsDetails[this.detailData.type]
      let rubricOption = rubric ? rubric.validRubrics : []
      this.rubricOptions = rubricOption.map((element) => {
        return {
          label: this.handleFormatLabel(element),
          value: element,
        }
      })
    },
    handleUnionShowInfo(info) {
      const { editable, showInfo } = handleGetDetailFormat(info, this.info.type)
      let sendInfo = ''

      for (const key in editable) {
        if (editable[key]?.value) {
          sendInfo += `${this.handleFormatLabel(key)}: ${
            editable[key]?.value
          } ,`
        }
      }
      for (const key in showInfo) {
        if (showInfo[key]?.value) {
          sendInfo += `${showInfo[key]?.label}: ${showInfo[key]?.value},`
        }
      }

      return sendInfo
    },
  },
  computed: {
    getCurrentRubricName() {
      const name = this.rubricsDetails[this.info.type]
      return name ? name.rubricName : 'Desconhecida'
    },
    getBtnLabel() {
      return this.isTransfer ? 'Transferir' : 'Salvar'
    },
    getBtnActionStatus() {
      if (this.isTransfer) {
        return this.selectedRubricTarget.length <= 0
      } else {
        return false
      }
    },
    getDrawerTitle() {
      return this.labels[this.action].title
    },

    handleShowInfo() {
      return this.detailData.showInfo
    },
    getUnionSum() {
      let newValue = 0
      if (this.info.type === 'direct') {
        this.info.rowInfo.forEach(({ total }) => {
          newValue += total?.value || 0
        })
      }
      this.info.rowInfo.forEach(({ value }) => {
        newValue += value
      })
      return this.handleFormatValue(newValue)
    },
    handleChangeData() {
      return !this.isVisible
    },
  },
  watch: {
    professionalOptions() {
      this.inputOptions.professional = this.professionalOptions
    },
    schoolingOptions() {
      this.inputOptions.schooling = this.schoolingOptions.map((e) => {
        return { value: e, label: e }
      })
    },
    providerOptions() {
      this.inputOptions.provider = this.providerOptions.map((e) => {
        return { value: e, label: e }
      })
    },
    info() {
      this.handleFormatInfo()
      this.handleRubricOptions()
      this.inputOptions.accountStruct = this.accountStructOptions[
        this.detailData.type
      ]?.map((e) => {
        return { value: e, label: e }
      })
    },
    selectedRubricTarget() {
      this.handleFormatInfo()
      this.handleShowDetail()
      this.inputOptions.accountStruct = this.accountStructOptions[
        this.selectedRubricTarget
      ]?.map((e) => {
        return { value: e, label: e }
      })
    },
    action() {
      this.isTransfer = this.action === 'transfer'
      this.isUnion = this.action === 'union'
      this.isAdjustment = this.action === 'adjustment'
    },
    isVisible() {
      this.selectedRubricTarget = ''
      if (this.isTransfer) {
        this.showDetail = false
        this.showDetailHeader = true
      } else if (this.isUnion) {
        this.showDetail = true
        this.showDetailHeader = false
      } else {
        this.showDetail = true
        this.showDetailHeader = true
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
