<template>
  <el-row class="input-container">
    <span>{{ dataInformation.label }}</span>
    <el-input
      v-if="dataInformation.type === 'text'"
      v-model="dataInformation.value"
      :placeholder="dataInformation.placeholder"
      :disabled="inputState"
    >
    </el-input>
    <el-input
      v-if="dataInformation.type === 'register'"
      v-model="dataInformation.value"
      v-mask="mask"
      maxlength="18"
      :disabled="inputState"
      @input="handleRegisterMask"
    />
    <el-input
      v-if="dataInformation.type === 'cpf'"
      v-model="dataInformation.value"
      v-mask="mask"
      maxlength="14"
      :disabled="inputState"
      @input="handleRegisterMask"
    />
    <el-input
      v-else-if="dataInformation.type === 'textarea'"
      type="textarea"
      v-model="dataInformation.value"
      :rows="dataInformation.rows"
      :placeholder="dataInformation.placeholder"
      :show-word-limit="dataInformation.showLimit"
      :maxlength="dataInformation.maxlength"
      :disabled="inputState"
    >
    </el-input>
    <el-input-number
      v-else-if="dataInformation.type === 'integer'"
      v-model="dataInformation.value"
      :controls-position="dataInformation.position"
      :size="dataInformation.size"
      :min="dataInformation.min"
      :max="dataInformation.max"
      :disabled="inputState"
    >
    </el-input-number>
    <el-select
      v-else-if="dataInformation.type === 'select'"
      v-model="dataInformation.value"
      :placeholder="dataInformation.placeholder"
      :disabled="inputState"
      @change="handleSelect"
    >
      <el-option
        v-for="(item, index) in inputOptions"
        :key="`select-${item.label}-${index}`"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-autocomplete
      v-else-if="dataInformation.type === 'autoComplete'"
      v-model="dataInformation.value"
      :placeholder="dataInformation.placeholder"
      :fetch-suggestions="querySearch"
    />
  </el-row>
</template>

<script>
export default {
  name: 'GenericInput',
  props: {
    inputData: Object,
    inputState: Boolean,
    inputOptions: Array,
    onClear: Boolean,
  },
  data() {
    return {
      mask: '###.###.###-##',
      dataInformation: {},
    }
  },
  methods: {
    querySearch(queryString, cb) {
      let options = this.inputOptions
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options
      cb(results)
    },
    createFilter(queryString) {
      return (option) => {
        return (
          option.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelect(data) {
      this.$emit('select', {
        propName: this.dataInformation.propName,
        ...this.inputOptions.find(({ value }) => value === data),
      })
    },
    handleRegisterMask(register) {
      const clearRegister = register?.replace(/\D/g, '')

      this.mask =
        clearRegister?.length <= 11 ? '###.###.###-##' : '##.###.###/####-##'
    },
    handleRegister() {
      this.dataInformation = this.inputData
      if (this.dataInformation.type === 'register') {
        this.dataInformation.value = this.handleFormatRegister(
          this.dataInformation.value
        )
      } else if (this.dataInformation.type === 'cpf') {
        this.dataInformation.value = this.handleFormatCPF(
          this.dataInformation.value
        )
      }
      this.handleRegisterMask(this.dataInformation.value)
    },
  },
  created() {
    this.handleRegister()
  },
  watch: {
    inputData() {
      this.handleRegister()
    },
    onClear(data) {
      if (data) {
        this.dataInformation = {}
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
