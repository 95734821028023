import api from '../instance'

export function getRubricsDetails() {
  return api.get('/rubrics')
}

export function transferRubric({
  idList,
  rubricId,
  details,
  collabValueId,
  correlateId,
  financialReportId,
}) {
  return api.patch('/rubrics', {
    idList,
    rubricId,
    details,
    collabValueId,
    correlateId,
    financialReportId,
  })
}
