<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      drawerName="Transferir lançamento"
      btnLabel="Transferir"
      :isBtnDisabled="getBtnActionStatus"
      @close="handleClose"
      @save="handleSaveRubric"
    >
      <div class="drawer-detail">
        <div class="transfer-container">
          <span class="bold"> Transferir lançamento para outra rubrica </span>
          <div class="transfer-info">
            Rubrica atual do lançamento
            <span>
              {{ getCurrentRubricName }}
            </span>
          </div>

          <el-select
            v-model="selectedRubricTarget"
            id="locale-filter"
            clearable
            filterable
            disabled
          >
            <el-option
              v-for="(element, index) in rubricOptions"
              :key="'TargetRubric' + index"
              :label="element.label"
              :value="element.value"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="showDetail">
          <div v-if="showDetailHeader">
            <span> Informações do lançamento: </span>
            <div div class="drawer-detail__header">
              <el-row
                v-for="(info, index) in handleShowInfo"
                :key="'drawerInfo' + index"
              >
                <span>{{ info.label }}</span>
                {{ info.value }}
              </el-row>
            </div>
            <span> Informações editaveis: </span>
          </div>
          <div class="drawer-detail__edit" v-if="hasDetail">
            <el-row class="inputs-container">
              <el-row class="inputs-container__row">
                <span>Tipo de despesa</span>
                <el-select
                  v-model="detailData.editable.expenseType.value"
                  placeholder="Selecionar tipo de despesa"
                  :disabled="isUnion"
                >
                  <el-option
                    v-for="item in expenseTypeOptions"
                    :key="`select-expenseType-type-${item.label}`"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-row>
              <el-row class="inputs-container__row">
                <span>Nome </span>
                <el-select
                  v-model="collaboratorId"
                  placeholder="Selecionar colaborador"
                  @change="handleChangeCollaborator"
                >
                  <el-option
                    v-for="item in collaborators"
                    :key="`select-colaborator-${item.id}`"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-row>
              <el-row class="inputs-container__row">
                <span>CPF</span>
                <el-input
                  v-model="detailData.editable.cpf.value"
                  disabled
                  v-mask="'###.###.###-##'"
                />
              </el-row>
              <el-row class="inputs-container__row">
                <span>Função do colaborador</span>
                <el-input v-model="detailData.editable.role.value" disabled />
              </el-row>
              <el-row class="inputs-container__row">
                <span>Formação</span>
                <el-input
                  v-model="detailData.editable.formation.value"
                  placeholder="Informe a formação do colaborador"
                />
              </el-row>
              <el-row class="inputs-container__row">
                <span>Escolaridade </span>
                <el-select
                  v-model="detailData.editable.schooling.value"
                  placeholder="Selecionar nível de escolaridade"
                >
                  <el-option
                    v-for="item in schoolingOptions"
                    :key="`select-schooling-${item}`"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-row>
              <el-row class="inputs-container__row">
                <span>Horas trabalhadas </span>
                <el-input-number
                  v-model="detailData.editable.workedHours.value"
                  controls-position="right"
                  :min="0"
                />
              </el-row>
              <el-row class="inputs-container__row">
                <span>Atividade </span>
                <el-input
                  type="textarea"
                  v-model="detailData.editable.activity.value"
                  rows="5"
                  show-word-limit
                  maxlength="1000"
                  placeholder="Atividade do colaborador..."
                />
              </el-row>
            </el-row>
          </div>
          <div v-else>
            <p>Esse lançamento não pode ser editado</p>
          </div>
        </div>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'
import { handleGetDetailFormat, handleFormatLabels } from '@/utils/rubrics'

export default {
  name: 'DrawerRHTransfer',
  components: {
    Drawer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    rubricsDetails: {
      type: Object,
      default: () => {},
    },
    collaborators: {
      type: Array,
      default: () => [],
    },
    professionalOptions: {
      type: Array,
      default: () => [],
    },
    providerOptions: {
      type: Array,
      default: () => [],
    },
    schoolingOptions: {
      type: Array,
      default: () => [],
    },
    accountStructOptions: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
  },
  data() {
    return {
      type: '',
      detailData: {
        editable: {
          name: '',
          expenseType: '',
          cpf: '',
          role: '',
          formation: '',
          schooling: '',
          workedHours: '',
          activity: '',
        },
      },
      rubricOptions: [],
      selectedRubricTarget: '',
      showDetail: false,
      inputOptions: {
        expenseType: [
          { value: 'sheet', label: 'Salário' },
          { value: 'prizes', label: 'Premiação' },
          { value: 'charges', label: 'Encargo' },
          { value: 'benefits', label: 'Benefício' },
        ],
        accountStruct: [],
        professional: [],
        schooling: [],
        provider: [],
      },
      collaboratorId: '',
      colaboratorOptions: [],
      expenseTypeOptions: [
        { value: 'sheet', label: 'Salário' },
        { value: 'prizes', label: 'Premiação' },
        { value: 'charges', label: 'Encargo' },
        { value: 'benefits', label: 'Benefício' },
      ],
      isUnion: false,
    }
  },
  methods: {
    handleChangeCollaborator() {
      const { cpf, role, name } = this.collaborators.find(
        (element) => element.id === this.collaboratorId
      )
      this.detailData.transferInfo.collaboratorId = this.collaboratorId
      this.detailData.editable.name.value = name
      this.detailData.editable.cpf.value = cpf
      this.detailData.editable.role.value = role
    },
    handleClose() {
      this.$emit('close')
    },
    handleSaveRubric() {
      let { type, editable, transferInfo, correlateId } = this.detailData
      let sendType = type
      let sendEditable = JSON.parse(JSON.stringify(editable))

      for (const key in sendEditable) {
        sendEditable[key] = sendEditable[key].value
      }

      const rubricId = this.rubricsDetails[sendType].rubricId
      const idList = this.info.rowInfo.ledgerBookList.map(({ id }) => {
        return id
      })

      let details = {
        ...sendEditable,
        ...transferInfo,
        collaboratorId: this.collaboratorId,
      }
      const { collabValueId } = transferInfo

      details = {
        ...details,
        ...this.detailData.transferInfo,
      }

      const sendData = {
        collabValueId,
        details,
        sendType,
        rubricId,
        idList,
        correlateId: this.isUnion ? correlateId : '',
        type: this.info.type,
      }
      this.$emit('transfer', sendData)
    },
    handleFormatInfo() {
      let targetType = this.info.type
      let data = this.info.rowInfo

      targetType =
        this.selectedRubricTarget.length > 0
          ? this.selectedRubricTarget
          : this.info.type

      const formatDetail = handleGetDetailFormat(data, targetType)

      this.detailData = formatDetail

      const { name, cpf, isCorrelate } = this.info.rowInfo

      const { id } =
        this.collaborators?.find(
          (element) => element?.name === name && element?.cpf === cpf
        ) || ''

      this.collaboratorId = id

      this.isUnion = isCorrelate
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
    handleShowDetail() {
      this.showDetail = this.selectedRubricTarget.length > 0
      this.showDetailHeader = this.showDetail
    },
    handleRubricOptions() {
      const rubric = this.rubricsDetails[this.info.type]
      let rubricOption = rubric ? rubric.validRubrics : []
      this.rubricOptions = rubricOption.map((element) => {
        return {
          label: this.handleFormatLabel(element),
          value: element,
        }
      })
    },
  },
  computed: {
    getCurrentRubricName() {
      const name = this.rubricsDetails[this.info.type]
      return name ? name.rubricName : 'Desconhecida'
    },
    getBtnActionStatus() {
      return this.selectedRubricTarget.length <= 0
    },
    handleShowInfo() {
      return this.detailData.showInfoRH
    },
    hasDetail() {
      return (
        this.info.rowInfo.correlateId ||
        this.info.rowInfo.ledgerBookList.length > 0
      )
    },
  },
  watch: {
    professionalOptions() {
      this.inputOptions.professional = this.professionalOptions
    },
    schoolingOptions() {
      this.inputOptions.schooling = this.schoolingOptions.map((e) => {
        return { value: e, label: e }
      })
    },
    providerOptions() {
      this.inputOptions.provider = this.providerOptions.map((e) => {
        return { value: e, label: e }
      })
    },
    info() {
      this.handleFormatInfo()
      this.handleRubricOptions()
      this.inputOptions.accountStruct = this.accountStructOptions[
        this.detailData.type
      ]?.map((e) => {
        return { value: e, label: e }
      })
    },
    selectedRubricTarget() {
      this.handleFormatInfo()
      this.handleShowDetail()
    },
    isVisible() {
      this.selectedRubricTarget = this.rubricOptions[0]?.value || ''
      this.handleShowDetail()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
