<template>
  <div class="header-info-container">
    <el-button
      icon="el-icon-arrow-left"
      id="return-button"
      @click="handleReturn"
    ></el-button>
    <div class="info-container">
      <span>
        Detalhamento centro de custo {{ info.centerCostCode }} -
        {{ info.projectName }}
      </span>
      <span class="info-container-date"
        >Mês de referência:
        {{ handleMonthAndYearFormatter(info.startDay) }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderInfo',
  data() {
    return {}
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleReturn() {
      this.$router.back()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
