<template>
  <el-main>
    <Drawer
      :visibility="isVisible"
      :isBtnDisabled="btnStatus"
      drawerName="Justar rateio do colaborador"
      @close="handleClose"
      @save="handleRateio"
    >
      <div class="drawer-detail">
        <span> Informações do lançamento: </span>
        <div div class="drawer-detail__header">
          <el-row>
            <span>Nome</span>
            {{ info.rowInfo?.name || '-' }}
          </el-row>
          <el-row>
            <span>CPF</span>
            {{ info.rowInfo?.cpf || '-' }}
          </el-row>
          <el-row>
            <span>Função do colaborador</span>
            {{ info.rowInfo?.role || '-' }}
          </el-row>
        </div>

        <div class="rateio-options-container" v-if="rateioOptions?.length > 0">
          <div
            v-for="(option, index) in rateioOptions"
            class="rateio-checkbox-container"
            :key="`rateio-option-${index}`"
          >
            <div>
              <span>Origen do rateio: </span>
              <span class="rateio-origin">{{ option.origin }}</span>
            </div>
            <div class="rateio-checkbox__wrapper">
              <el-radio
                v-model="option.isRemove"
                class="checkbox__body"
                border
                label="percent"
              >
                <span> Percentual do rateio </span>
                <div class="percent-input">
                  <el-input-number
                    :disabled="option.isRemove !== 'percent'"
                    v-model="option.distributionApportion"
                    :step="1"
                    :min="0.01"
                    :max="100"
                    :precision="2"
                  ></el-input-number>
                </div>
              </el-radio>
            </div>
            <div class="rateio-checkbox__wrapper">
              <el-radio
                v-model="option.isRemove"
                class="checkbox__body"
                border
                label="remove"
              >
                <span> Remover rateio </span>
              </el-radio>
            </div>
          </div>
        </div>

        <div v-else class="rateio-checkbox-container">
          <div class="rateio-checkbox__wrapper">
            <el-radio
              v-model="isRateioPercent"
              class="checkbox__body"
              border
              label="percent"
            >
              <span> Percentual do rateio </span>
              <div class="percent-input">
                <el-input-number
                  :disabled="isRateioPercent !== 'percent'"
                  v-model="rateioPercent"
                  :step="1"
                  :min="0.01"
                  :max="100"
                  :precision="2"
                ></el-input-number>
              </div>
            </el-radio>
          </div>
          <div class="rateio-checkbox__wrapper">
            <el-radio
              v-model="isRateioPercent"
              class="checkbox__body"
              border
              label="remove"
            >
              <span> Remover rateio </span>
            </el-radio>
          </div>
        </div>
      </div>
    </Drawer>
  </el-main>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'
import { handleFormatLabels } from '@/utils/rubrics'

export default {
  name: 'DrawerRHRateio',
  components: {
    Drawer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {
        return {
          type: '',
          rowInfo: {},
        }
      },
    },
  },
  data() {
    return {
      rateioPercent: 0.01,
      isRateioPercent: 'percent',
      rateioOptions: [],
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleFormatLabel(label) {
      return handleFormatLabels(label)
    },
    handleRateio() {
      const { correlateId, ledgerBookList, isCorrelate } = this.info.rowInfo
      const ledgerBookIdList = ledgerBookList.map(({ id }) => id)

      const distributions =
        this.rateioOptions.length > 0
          ? this.rateioOptions.map((rateio) => ({
              distributionId: rateio.distributionId,
              distributionApportion:
                rateio.isRemove === 'percent'
                  ? rateio.distributionApportion
                  : 0,
            }))
          : [
              {
                distributionApportion:
                  this.isRateioPercent === 'percent' ? this.rateioPercent : 0,
              },
            ]

      const sendInfo = {
        ...this.info,
        ledgerBookIdList,
        correlateId: isCorrelate ? correlateId : '',
        distributions,
      }

      this.$emit('submit', {
        info: sendInfo,
      })
    },
    handleGetOriginalApportion() {
      return this.info.rowInfo?.distributions?.reduce((acc, curr) => {
        return acc + curr?.distributionApportion || 0
      }, 0)
    },
    handleFormatOptions() {
      this.rateioOptions =
        this.info.rowInfo?.distributions?.map((distribution) => {
          return {
            distributionApportion: distribution?.distributionApportion || 0,
            distributionId: distribution?.distributionId || null,
            isRemove: 'percent',
            origin: distribution.origin,
          }
        }) || []
    },
  },
  computed: {
    btnStatus() {
      const totalApport =
        this.rateioOptions?.length > 0
          ? this.rateioOptions?.reduce((prev, curr) => {
              const newCurr =
                curr?.isRemove === 'percent' ? curr?.distributionApportion : 0

              return prev + newCurr
            }, 0)
          : this.isRateioPercent === 'percent'
          ? this.rateioPercent
          : 0

      const isSame =
        this.handleGetOriginalApportion() === 0 && totalApport === 0

      return totalApport > 100 || isSame
    },
  },
  watch: {
    info() {
      this.rateioPercent = 0.01
      this.handleFormatOptions()
    },
    isVisible(value) {
      if (!value) {
        this.isRateioPercent = 'percent'
        this.rateioPercent = 0.01
      }
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
