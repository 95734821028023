import mixins from '@/mixins'

const tableConfig = {
  correlates1: {
    searchColumns: [
      { propName: 'accountStruct', propType: 'String' },
      { propName: 'justification', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'value', propType: 'Number' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Conta contábil',
        prop: 'accountStruct',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'N° nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Fornecedor encontrado',
        prop: 'provider',
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  correlates2: {
    searchColumns: [
      { propName: 'accountStruct', propType: 'String' },
      { propName: 'justification', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'value', propType: 'Number' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Conta contábil',
        prop: 'accountStruct',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'N° nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Fornecedor encontrado',
        prop: 'provider',
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  books: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'title', propType: 'String' },
      { propName: 'quantity', propType: 'String' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Título',
        prop: 'title',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Quantidade',
        prop: 'quantity',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  external: {
    searchColumns: [
      { propName: 'name', propType: 'String' },
      { propName: 'role', propType: 'String' },
      { propName: 'value', propType: 'Number' },
      { propName: 'register', propType: 'Register' },
    ],
    actions: [
      { value: 'detail', label: 'Detalhar' },
      { value: 'onEntries', label: 'Lançamentos' },
    ],
    tableColumnsProps: [
      {
        label: 'Colaborador',
        prop: 'name',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'CNPJ/CPF',
        prop: 'register',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Função colaborador',
        prop: 'role',
        minWidth: 200,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Horas',
        prop: 'workedHours',
        minWidth: 150,
        formatter: formatHours,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Escolaridade',
        prop: 'schooling',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Formação',
        prop: 'formation',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Tipo',
        prop: 'apportionType',
        minWidth: 150,
        formatter: formatExternalTypeTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Lançamentos',
        prop: 'hasEntry',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Atividade',
        prop: 'activity',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  direct: {
    searchColumns: [
      { propName: 'name', propType: 'String' },
      { propName: 'role', propType: 'String' },
      { propName: 'formation', propType: 'String' },
      { propName: 'schooling', propType: 'String' },
      { propName: 'workedHours', propType: 'String' },
      { propName: 'value', propType: 'Number' },
      { propName: 'sheet', propType: 'LedgerBookValue' },
      { propName: 'prizes', propType: 'LedgerBookValue' },
      { propName: 'charges', propType: 'LedgerBookValue' },
      { propName: 'benefits', propType: 'LedgerBookValue' },
      { propName: 'cpf', propType: 'Register' },
    ],
    actions: [],
    tableColumnsProps: [
      {
        label: 'Nome',
        prop: 'name',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'CPF',
        prop: 'cpf',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Função colaborador',
        prop: 'role',
        minWidth: 200,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Formação',
        prop: 'formation',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Escolaridade',
        prop: 'schooling',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Horas',
        prop: 'workedHours',
        minWidth: 150,
        formatter: formatHours,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Atividade',
        prop: 'activity',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  notAllocated: {
    searchColumns: [
      { propName: 'value', propType: 'String' },
      { propName: 'reduced', propType: 'String' },
      { propName: 'accountStruct', propType: 'String' },
      { propName: 'complement', propType: 'String' },
      { propName: 'date', propType: 'String' },
      { propName: 'document', propType: 'String' },
    ],
    actions: [{ label: 'Transferir', value: 'transfer' }],
    tableColumnsProps: [
      {
        label: 'Valor',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Reduzido',
        prop: 'reduced',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Conta contábil',
        prop: 'accountStruct',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Descrição',
        prop: 'complement',
        minWidth: 200,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Data',
        prop: 'date',
        minWidth: 150,
        formatter: formatTableDate,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Documento',
        prop: 'document',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Sub rubríca',
        prop: 'rubric',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [],
  },
  indirect: {
    searchColumns: [
      { propName: 'name', propType: 'String' },
      { propName: 'role', propType: 'String' },
      { propName: 'formation', propType: 'String' },
      { propName: 'schooling', propType: 'String' },
      { propName: 'workedHours', propType: 'String' },
      { propName: 'value', propType: 'Number' },
      { propName: 'sheet', propType: 'LedgerBookValue' },
      { propName: 'prizes', propType: 'LedgerBookValue' },
      { propName: 'charges', propType: 'LedgerBookValue' },
      { propName: 'benefits', propType: 'LedgerBookValue' },
      { propName: 'cpf', propType: 'Register' },
    ],
    actions: [],
    tableColumnsProps: [
      {
        label: 'Nome',
        prop: 'name',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'CPF',
        prop: 'cpf',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Função colaborador',
        prop: 'role',
        minWidth: 200,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Formação',
        prop: 'formation',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Escolaridade',
        prop: 'schooling',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Horas',
        prop: 'workedHours',
        minWidth: 150,
        formatter: formatHours,
        headerAlign: 'center',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Atividade',
        prop: 'activity',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  civil: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'provider', propType: 'String' },
      { propName: 'description', propType: 'String' },
      { propName: 'register', propType: 'Register' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'CNPJ/CPF',
        prop: 'register',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Obra realizada',
        prop: 'description',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  leasing: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'quantity', propType: 'String' },
      { propName: 'contract', propType: 'String' },
      { propName: 'description', propType: 'String' },
    ],
    actions: [
      { value: 'detail', label: 'Detalhar' },
      { value: 'leasing', label: 'Lançamento' },
    ],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Descrição',
        prop: 'description',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'N° do contrato',
        prop: 'contract',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Quantidade',
        prop: 'quantity',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'N° nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Lançamento',
        prop: 'hasLeasing',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  equip: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'MCT', propType: 'String' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'quantity', propType: 'String' },
      { propName: 'description', propType: 'String' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Descrição',
        prop: 'description',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Quantidade',
        prop: 'quantity',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'N° nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
      {
        label: 'Classificação (MCT)',
        prop: 'MCT',
        minWidth: 180,
        formatter: formatTableData,
        headerAlign: 'left',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  consumables: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'quantity', propType: 'String' },
      { propName: 'description', propType: 'String' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Descrição',
        prop: 'description',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Quantidade',
        prop: 'quantity',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'N° nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  trips: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'period', propType: 'String' },
      { propName: 'destiny', propType: 'String' },
      { propName: 'location', propType: 'String' },
      { propName: 'professional', propType: 'String' },
    ],
    actions: [
      { value: 'detail', label: 'Detalhar' },
      { value: 'attach', label: 'Informações anexadas' },
    ],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Profissional',
        prop: 'professional',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Local',
        prop: 'location',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Período',
        prop: 'period',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Lançamentos anexados',
        prop: 'tripsGroupId',
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  training: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'hours', propType: 'String' },
      { propName: 'period', propType: 'String' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'subject', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'quantity', propType: 'String' },
      { propName: 'objective', propType: 'String' },
      { propName: 'description', propType: 'String' },
    ],
    actions: [
      { value: 'detail', label: 'Detalhar' },
      { value: 'onEntries', label: 'Participantes' },
    ],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Objetivo',
        prop: 'objective',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Assunto',
        prop: 'subject',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Período',
        prop: 'period',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Carga horária',
        prop: 'hours',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'center',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Lançamentos',
        prop: 'hasEntry',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Atividade',
        prop: 'activity',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
  services: {
    searchColumns: [
      { propName: 'value', propType: 'Number' },
      { propName: 'invoice', propType: 'String' },
      { propName: 'provider', propType: 'String' },
      { propName: 'quantity', propType: 'String' },
      { propName: 'description', propType: 'String' },
      { propName: 'register', propType: 'Register' },
    ],
    actions: [{ value: 'detail', label: 'Detalhar' }],
    managerActions: [{ value: 'detail', label: 'Justificar' }],
    tableColumnsProps: [
      {
        label: 'Fornecedor',
        prop: 'provider',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'CNPJ/CPF',
        prop: 'register',
        minWidth: 150,
        formatter: handleFormatRegister,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'Valor total',
        prop: 'value',
        minWidth: 150,
        formatter: formatTableValue,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Descrição dos serviços',
        prop: 'description',
        minWidth: 200,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
      {
        label: 'N° nota fiscal',
        prop: 'invoice',
        minWidth: 150,
        formatter: formatTableData,
        headerAlign: 'center',
        align: 'left',
      },
    ],
    tableIconColumnsProps: [
      {
        label: 'Unido',
        prop: 'isCorrelate',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
      {
        label: 'Justificativa',
        prop: 'justification',
        minWidth: 180,
        headerAlign: 'center',
        align: 'center',
      },
    ],
  },
}

function formatTableValue(row, column, cellValue, index) {
  return mixins.methods.handleFormatValue(cellValue)
}

function formatTableDate(row, column, cellValue, index) {
  return mixins.methods.handleFormatDate(cellValue)
}

function formatHours(row, column, cellValue, index) {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  return Number(cellValue) === 0 || isNaN(Number(cellValue))
    ? '-'
    : Number(cellValue).toLocaleString('pt-BR', options)
}

function formatTableData(row, column, cellValue, index) {
  return cellValue !== undefined && String(cellValue)?.length > 0
    ? cellValue
    : '-'
}

function formatExternalTypeTableData(row, column, cellValue, index) {
  const typesLabel = {
    direct: 'Direto',
    indirect: 'Indireto',
  }
  return cellValue !== undefined && cellValue?.length > 0
    ? typesLabel[cellValue]
    : '-'
}

function handleFormatRegister(row, column, cellValue, index) {
  return mixins.methods.handleFormatRegister(cellValue)
}

export default tableConfig
